import { VotingPolicy } from '@/application/proto/tumelo/retailbff/v1/voting_policy'

export const SampleVotingPolicy = (override?: Partial<VotingPolicy>): VotingPolicy =>
  VotingPolicy.fromPartial({
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
    imageUrl: '/images/sample/policy-providers/logos/glass-lewis.png',
    currentDocument: { pdfUrl: '/documents/sample/policy-document.pdf' },
    policyProvider: { title: 'Glass Lewis' },
    description: `#### Reducing climate risk

- Identifying company-specific climate risks.
- Encouraging oversight on climate risks.

#### Promoting transparency and climate-related disclosures

- Report on a company's environmental and social risks.
- Plans to reduce greenhouse gas emissions.

#### Tying exec pay to environmental and social goals

- Ensuring companies with greater climate risks have stricter mitigation plans.`,
    ...override,
  })
