import React from 'react'
import Head from 'next/head'
import { Montserrat } from 'next/font/google'
import type { AppProps } from 'next/app'
import { Providers } from '@/application/Providers'
import { useHashRedirect } from '@/application/features/hashRedirect/useHashRedirect'

const montserrat = Montserrat({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  useHashRedirect()
  return (
    <>
      <Head>
        <title>Tumelo ProxySphere - Retail</title>
        <link rel="icon" href="/images/favicon.ico" type="image/x-icon" />
      </Head>
      <div className={montserrat.className}>
        <Providers>
          <Component {...pageProps} />
        </Providers>
      </div>
    </>
  )
}

export default MyApp
