import { useState } from 'react'
import { useLogger } from '@tumelo/logging'

// based off of https://usehooks.com/useLocalStorage/
export const useLocalStorage = <T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return defaultValue
    }
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : defaultValue
    } catch (error) {
      logger.logError(error, { extra: { where: 'useLocalStorage' } })
      return defaultValue
    }
  })
  const logger = useLogger()

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      logger.logError(error, { extra: { where: 'useLocalStorage' } })
    }
  }

  return [storedValue, setValue]
}
