import { AccountSummary, AccountsSummary } from '@/application/proto/tumelo/retailbff/v1/account'
import { AjBell } from './broker'

export const SampleAccountSummary = (override?: Partial<AccountSummary>): AccountSummary =>
  AccountSummary.fromPartial({
    accountTitle: 'ISA',
    investments: [
      { investmentTitle: 'Schroder Asian Discovery', isPassthroughVotable: true },
      { investmentTitle: 'BlackRock Continental European Income', isPassthroughVotable: true },
      { investmentTitle: 'BlackRock Asia', isPassthroughVotable: true },
      { investmentTitle: 'Artemis European Select', isPassthroughVotable: false },
    ],
    ...override,
  })

export const SampleAccountsSummary = (override?: Partial<AccountsSummary>): AccountsSummary =>
  AccountsSummary.fromPartial({
    broker: AjBell,
    accounts: [
      SampleAccountSummary(),
      SampleAccountSummary({
        accountTitle: 'SIP',
        investments: [{ investmentTitle: 'FSSA Greater China Growth', isPassthroughVotable: true }],
      }),
    ],
    ...override,
  })
