import { Broker } from '@/application/proto/tumelo/retailbff/v1/broker'

export const SampleBroker = (override?: Partial<Broker>): Broker =>
  Broker.fromPartial({
    name: 'broker-connectors/moneyhub/brokers/ba4218c7-b826-48b2-a3f5-8c8c8a10a67b',
    title: 'Hargreaves Lansdown',
    logoUrl: '/images/sample/brokers/logos/hargreaves-lansdown.jpeg',
    ...override,
  })

export const HargreavesLansdown = SampleBroker()

export const AjBell = SampleBroker({
  name: 'broker-connectors/moneyhub/brokers/325ef3bb-308c-4abf-a398-0d44d202fe45',
  title: 'AJ Bell',
  logoUrl: '/images/sample/brokers/logos/aj-bell.jpeg',
})

export const Vanguard = SampleBroker({
  name: 'broker-connectors/moneyhub/brokers/212f642d-4c34-4a50-acd8-b772b990d59b',
  title: 'Vanguard',
  logoUrl: '/images/sample/brokers/logos/vanguard.jpeg',
})

export const Fidelity = SampleBroker({
  name: 'broker-connectors/moneyhub/brokers/7f483175-b292-4780-a127-49ba0443b43cb',
  title: 'Fidelity',
  logoUrl: '/images/sample/brokers/logos/fidelity.jpeg',
})

export const CharlesSchwab = SampleBroker({
  name: 'broker-connectors/moneyhub/brokers/7596d4dc-e4df-4c60-aeab-a50b0f4a0010',
  title: 'Charles Schwab',
  logoUrl: '/images/sample/brokers/logos/charles-schwab.jpeg',
})
