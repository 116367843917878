/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "../../../google/protobuf/empty";
import { FieldMask } from "../../../google/protobuf/field_mask";
import { AccountsSummary } from "./account";
import { Broker } from "./broker";
import { BrokerConnection, BrokerConnectionWithBroker } from "./broker_connection";
import { VotableFundVariant } from "./votable_fund_variant";

export const protobufPackage = "tumelo.retailaccountconnector.v1";

/** BrokerConnectionFlow is the type of flow for the broker connection */
export enum BrokerConnectionFlow {
  BROKER_CONNECTION_FLOW_INVALID = 0,
  BROKER_CONNECTION_FLOW_NEW = 1,
  BROKER_CONNECTION_FLOW_REFRESH = 2,
  UNRECOGNIZED = -1,
}

export function brokerConnectionFlowFromJSON(object: any): BrokerConnectionFlow {
  switch (object) {
    case 0:
    case "BROKER_CONNECTION_FLOW_INVALID":
      return BrokerConnectionFlow.BROKER_CONNECTION_FLOW_INVALID;
    case 1:
    case "BROKER_CONNECTION_FLOW_NEW":
      return BrokerConnectionFlow.BROKER_CONNECTION_FLOW_NEW;
    case 2:
    case "BROKER_CONNECTION_FLOW_REFRESH":
      return BrokerConnectionFlow.BROKER_CONNECTION_FLOW_REFRESH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BrokerConnectionFlow.UNRECOGNIZED;
  }
}

export function brokerConnectionFlowToJSON(object: BrokerConnectionFlow): string {
  switch (object) {
    case BrokerConnectionFlow.BROKER_CONNECTION_FLOW_INVALID:
      return "BROKER_CONNECTION_FLOW_INVALID";
    case BrokerConnectionFlow.BROKER_CONNECTION_FLOW_NEW:
      return "BROKER_CONNECTION_FLOW_NEW";
    case BrokerConnectionFlow.BROKER_CONNECTION_FLOW_REFRESH:
      return "BROKER_CONNECTION_FLOW_REFRESH";
    case BrokerConnectionFlow.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SyncRetailAccountsRequest {
  /** parent is a broker_connection_name in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  parent: string;
}

export interface CreateBrokerRequest {
  title: string;
  logoUrl: string;
  externalId: string;
  brokerConnectorName: string;
}

export interface ListBrokersRequest {
  /** OPTIONAL page size */
  pageSize: number;
  /** OPTIONAL pagination token */
  pageToken: string;
  /** OPTIONAL the list of broker connector names to filter the brokers */
  brokerConnectorNames: string[];
  /** OPTIONAL a filter indicating whether to return all brokers or only active ones */
  activeOnly: boolean;
}

/** ListBrokersResponse */
export interface ListBrokersResponse {
  /** list of brokers to be returned. */
  brokers: Broker[];
  /**
   * next_page_token contains a string which can be used to get the next page of results.
   * it will be empty if there are no more results.
   */
  nextPageToken: string;
}

/** StartBrokerConnectionRequest */
export interface StartBrokerConnectionRequest {
  /** broker_name is in the format `brokerConnectors/moneyhub/broker/{uuid}` */
  brokerName: string;
  /** investor_name is in the format `habitats/{uuid}/investors/{uuid}` */
  investorName: string;
}

/** StartBrokerConnectionResponse */
export interface StartBrokerConnectionResponse {
  /**
   * the auth_url is a URL that we give to the user, which then promps them to
   * give us the consent and permissions to link to their broker account.
   */
  authUrl: string;
}

/** UpdateBrokerRequest */
export interface UpdateBrokerRequest {
  broker: Broker | undefined;
  updateMask: string[] | undefined;
}

export interface DeleteBrokerRequest {
  /** broker_name is in the format `brokerConnectors/moneyhub/broker/{uuid}` */
  brokerName: string;
}

export interface CompleteBrokerConnectionRequest {
  /**
   * the auth_code is the code returned in the redirect_uri from moneyhub which they
   * use to confirm that the user has given consent for us to access their data.
   */
  authCode: string;
  /** investor_name is in the format `habitats/{uuid}/investors/{uuid}` */
  investorName: string;
  /** broker_connector_name is in the format `brokerConnectors/moneyhub` */
  brokerConnectorName: string;
  /** broker_connection_flow is the type of flow for the broker connection */
  brokerConnectionFlow: BrokerConnectionFlow;
}

export interface DeleteBrokerConnectionRequest {
  /** broker_connection_name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface FetchBrokerConnectionSummaryRequest {
  /** broker_connection_name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface RefreshBrokerConnectionRequest {
  /** broker_connection_name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface RefreshBrokerConnectionResponse {
  refreshUrl: string;
}

export interface ListBrokerConnectionsRequest {
  /** OPTIONAL investor_name is in the format `habitats/{uuid}/investors/{uuid}` */
  investorName: string;
  /** OPTIONAL page size */
  pageSize: number;
  /** OPTIONAL pagination token */
  pageToken: string;
  /** OPTIONAL broker_names in the format `brokerConnectors/moneyhub/broker/{uuid}` to filter the broker connections */
  brokerNames: string[];
}

/** ListBrokerConnectionsResponse */
export interface ListBrokerConnectionsResponse {
  /** list of broker connections to be returned. */
  brokerConnections: BrokerConnectionWithBroker[];
  /**
   * next_page_token contains a string which can be used to get the next page of results.
   * it will be empty if there are no more results.
   */
  nextPageToken: string;
}

export interface CreateVotableFundVariantRequest {
  /** fund_variant_name is of the format funds/{uuid}/variants/{uuid} */
  fundVariantName: string;
}

export interface DeleteVotableFundVariantRequest {
  /** fund_variant_name is of the format funds/{uuid}/variants/{uuid} */
  fundVariantName: string;
}

export interface UpdateBrokerConnectionRequest {
  brokerConnection: BrokerConnection | undefined;
}

export interface ConnectionStats {
  /**
   * stale_connection_count is the number of connections that have a SUCCESSFUL sync status, but have not been
   * successfully synced within the last 24 hours.
   */
  staleConnectionCount: number;
  /** stale_connections_by_broker is a map of broker titles to the number of stale connections for that broker */
  staleConnectionsByBroker: { [key: string]: number };
  /** broker_connection_statuses is a list of connection status counts for each broker. */
  brokerConnectionStatuses: ConnectionStats_BrokerConnectionStatus[];
}

export interface ConnectionStats_BrokerConnectionStatus {
  /** broker_title is the human-readable title of the broker */
  brokerTitle: string;
  /** status_count is a map of AccountSyncStatus to the count of connections with that status */
  statusCount: { [key: string]: number };
}

export interface ConnectionStats_BrokerConnectionStatus_StatusCountEntry {
  key: string;
  value: number;
}

export interface ConnectionStats_StaleConnectionsByBrokerEntry {
  key: string;
  value: number;
}

function createBaseSyncRetailAccountsRequest(): SyncRetailAccountsRequest {
  return { parent: "" };
}

export const SyncRetailAccountsRequest = {
  encode(message: SyncRetailAccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncRetailAccountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyncRetailAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SyncRetailAccountsRequest {
    return { parent: isSet(object.parent) ? String(object.parent) : "" };
  },

  toJSON(message: SyncRetailAccountsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyncRetailAccountsRequest>, I>>(base?: I): SyncRetailAccountsRequest {
    return SyncRetailAccountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyncRetailAccountsRequest>, I>>(object: I): SyncRetailAccountsRequest {
    const message = createBaseSyncRetailAccountsRequest();
    message.parent = object.parent ?? "";
    return message;
  },
};

function createBaseCreateBrokerRequest(): CreateBrokerRequest {
  return { title: "", logoUrl: "", externalId: "", brokerConnectorName: "" };
}

export const CreateBrokerRequest = {
  encode(message: CreateBrokerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.logoUrl !== "") {
      writer.uint32(18).string(message.logoUrl);
    }
    if (message.externalId !== "") {
      writer.uint32(26).string(message.externalId);
    }
    if (message.brokerConnectorName !== "") {
      writer.uint32(34).string(message.brokerConnectorName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBrokerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBrokerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.brokerConnectorName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBrokerRequest {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      externalId: isSet(object.externalId) ? String(object.externalId) : "",
      brokerConnectorName: isSet(object.brokerConnectorName) ? String(object.brokerConnectorName) : "",
    };
  },

  toJSON(message: CreateBrokerRequest): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.brokerConnectorName !== "") {
      obj.brokerConnectorName = message.brokerConnectorName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBrokerRequest>, I>>(base?: I): CreateBrokerRequest {
    return CreateBrokerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBrokerRequest>, I>>(object: I): CreateBrokerRequest {
    const message = createBaseCreateBrokerRequest();
    message.title = object.title ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.externalId = object.externalId ?? "";
    message.brokerConnectorName = object.brokerConnectorName ?? "";
    return message;
  },
};

function createBaseListBrokersRequest(): ListBrokersRequest {
  return { pageSize: 0, pageToken: "", brokerConnectorNames: [], activeOnly: false };
}

export const ListBrokersRequest = {
  encode(message: ListBrokersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    for (const v of message.brokerConnectorNames) {
      writer.uint32(26).string(v!);
    }
    if (message.activeOnly === true) {
      writer.uint32(32).bool(message.activeOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerConnectorNames.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.activeOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokersRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      brokerConnectorNames: globalThis.Array.isArray(object?.brokerConnectorNames)
        ? object.brokerConnectorNames.map((e: any) => String(e))
        : [],
      activeOnly: isSet(object.activeOnly) ? Boolean(object.activeOnly) : false,
    };
  },

  toJSON(message: ListBrokersRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.brokerConnectorNames?.length) {
      obj.brokerConnectorNames = message.brokerConnectorNames;
    }
    if (message.activeOnly === true) {
      obj.activeOnly = message.activeOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokersRequest>, I>>(base?: I): ListBrokersRequest {
    return ListBrokersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokersRequest>, I>>(object: I): ListBrokersRequest {
    const message = createBaseListBrokersRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.brokerConnectorNames = object.brokerConnectorNames?.map((e) => e) || [];
    message.activeOnly = object.activeOnly ?? false;
    return message;
  },
};

function createBaseListBrokersResponse(): ListBrokersResponse {
  return { brokers: [], nextPageToken: "" };
}

export const ListBrokersResponse = {
  encode(message: ListBrokersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokers) {
      Broker.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokers.push(Broker.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokersResponse {
    return {
      brokers: globalThis.Array.isArray(object?.brokers) ? object.brokers.map((e: any) => Broker.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListBrokersResponse): unknown {
    const obj: any = {};
    if (message.brokers?.length) {
      obj.brokers = message.brokers.map((e) => Broker.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokersResponse>, I>>(base?: I): ListBrokersResponse {
    return ListBrokersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokersResponse>, I>>(object: I): ListBrokersResponse {
    const message = createBaseListBrokersResponse();
    message.brokers = object.brokers?.map((e) => Broker.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseStartBrokerConnectionRequest(): StartBrokerConnectionRequest {
  return { brokerName: "", investorName: "" };
}

export const StartBrokerConnectionRequest = {
  encode(message: StartBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerName !== "") {
      writer.uint32(10).string(message.brokerName);
    }
    if (message.investorName !== "") {
      writer.uint32(18).string(message.investorName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.investorName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartBrokerConnectionRequest {
    return {
      brokerName: isSet(object.brokerName) ? String(object.brokerName) : "",
      investorName: isSet(object.investorName) ? String(object.investorName) : "",
    };
  },

  toJSON(message: StartBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerName !== "") {
      obj.brokerName = message.brokerName;
    }
    if (message.investorName !== "") {
      obj.investorName = message.investorName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartBrokerConnectionRequest>, I>>(base?: I): StartBrokerConnectionRequest {
    return StartBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartBrokerConnectionRequest>, I>>(object: I): StartBrokerConnectionRequest {
    const message = createBaseStartBrokerConnectionRequest();
    message.brokerName = object.brokerName ?? "";
    message.investorName = object.investorName ?? "";
    return message;
  },
};

function createBaseStartBrokerConnectionResponse(): StartBrokerConnectionResponse {
  return { authUrl: "" };
}

export const StartBrokerConnectionResponse = {
  encode(message: StartBrokerConnectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authUrl !== "") {
      writer.uint32(10).string(message.authUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartBrokerConnectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartBrokerConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartBrokerConnectionResponse {
    return { authUrl: isSet(object.authUrl) ? String(object.authUrl) : "" };
  },

  toJSON(message: StartBrokerConnectionResponse): unknown {
    const obj: any = {};
    if (message.authUrl !== "") {
      obj.authUrl = message.authUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartBrokerConnectionResponse>, I>>(base?: I): StartBrokerConnectionResponse {
    return StartBrokerConnectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartBrokerConnectionResponse>, I>>(
    object: I,
  ): StartBrokerConnectionResponse {
    const message = createBaseStartBrokerConnectionResponse();
    message.authUrl = object.authUrl ?? "";
    return message;
  },
};

function createBaseUpdateBrokerRequest(): UpdateBrokerRequest {
  return { broker: undefined, updateMask: undefined };
}

export const UpdateBrokerRequest = {
  encode(message: UpdateBrokerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.broker !== undefined) {
      Broker.encode(message.broker, writer.uint32(10).fork()).ldelim();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBrokerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBrokerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.broker = Broker.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBrokerRequest {
    return {
      broker: isSet(object.broker) ? Broker.fromJSON(object.broker) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateBrokerRequest): unknown {
    const obj: any = {};
    if (message.broker !== undefined) {
      obj.broker = Broker.toJSON(message.broker);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBrokerRequest>, I>>(base?: I): UpdateBrokerRequest {
    return UpdateBrokerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBrokerRequest>, I>>(object: I): UpdateBrokerRequest {
    const message = createBaseUpdateBrokerRequest();
    message.broker = (object.broker !== undefined && object.broker !== null)
      ? Broker.fromPartial(object.broker)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseDeleteBrokerRequest(): DeleteBrokerRequest {
  return { brokerName: "" };
}

export const DeleteBrokerRequest = {
  encode(message: DeleteBrokerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerName !== "") {
      writer.uint32(10).string(message.brokerName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteBrokerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteBrokerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteBrokerRequest {
    return { brokerName: isSet(object.brokerName) ? String(object.brokerName) : "" };
  },

  toJSON(message: DeleteBrokerRequest): unknown {
    const obj: any = {};
    if (message.brokerName !== "") {
      obj.brokerName = message.brokerName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteBrokerRequest>, I>>(base?: I): DeleteBrokerRequest {
    return DeleteBrokerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteBrokerRequest>, I>>(object: I): DeleteBrokerRequest {
    const message = createBaseDeleteBrokerRequest();
    message.brokerName = object.brokerName ?? "";
    return message;
  },
};

function createBaseCompleteBrokerConnectionRequest(): CompleteBrokerConnectionRequest {
  return { authCode: "", investorName: "", brokerConnectorName: "", brokerConnectionFlow: 0 };
}

export const CompleteBrokerConnectionRequest = {
  encode(message: CompleteBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCode !== "") {
      writer.uint32(10).string(message.authCode);
    }
    if (message.investorName !== "") {
      writer.uint32(18).string(message.investorName);
    }
    if (message.brokerConnectorName !== "") {
      writer.uint32(26).string(message.brokerConnectorName);
    }
    if (message.brokerConnectionFlow !== 0) {
      writer.uint32(32).int32(message.brokerConnectionFlow);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompleteBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.investorName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerConnectorName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.brokerConnectionFlow = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompleteBrokerConnectionRequest {
    return {
      authCode: isSet(object.authCode) ? String(object.authCode) : "",
      investorName: isSet(object.investorName) ? String(object.investorName) : "",
      brokerConnectorName: isSet(object.brokerConnectorName) ? String(object.brokerConnectorName) : "",
      brokerConnectionFlow: isSet(object.brokerConnectionFlow)
        ? brokerConnectionFlowFromJSON(object.brokerConnectionFlow)
        : 0,
    };
  },

  toJSON(message: CompleteBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.authCode !== "") {
      obj.authCode = message.authCode;
    }
    if (message.investorName !== "") {
      obj.investorName = message.investorName;
    }
    if (message.brokerConnectorName !== "") {
      obj.brokerConnectorName = message.brokerConnectorName;
    }
    if (message.brokerConnectionFlow !== 0) {
      obj.brokerConnectionFlow = brokerConnectionFlowToJSON(message.brokerConnectionFlow);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompleteBrokerConnectionRequest>, I>>(base?: I): CompleteBrokerConnectionRequest {
    return CompleteBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompleteBrokerConnectionRequest>, I>>(
    object: I,
  ): CompleteBrokerConnectionRequest {
    const message = createBaseCompleteBrokerConnectionRequest();
    message.authCode = object.authCode ?? "";
    message.investorName = object.investorName ?? "";
    message.brokerConnectorName = object.brokerConnectorName ?? "";
    message.brokerConnectionFlow = object.brokerConnectionFlow ?? 0;
    return message;
  },
};

function createBaseDeleteBrokerConnectionRequest(): DeleteBrokerConnectionRequest {
  return { brokerConnectionName: "" };
}

export const DeleteBrokerConnectionRequest = {
  encode(message: DeleteBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteBrokerConnectionRequest {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: DeleteBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteBrokerConnectionRequest>, I>>(base?: I): DeleteBrokerConnectionRequest {
    return DeleteBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteBrokerConnectionRequest>, I>>(
    object: I,
  ): DeleteBrokerConnectionRequest {
    const message = createBaseDeleteBrokerConnectionRequest();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseFetchBrokerConnectionSummaryRequest(): FetchBrokerConnectionSummaryRequest {
  return { brokerConnectionName: "" };
}

export const FetchBrokerConnectionSummaryRequest = {
  encode(message: FetchBrokerConnectionSummaryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchBrokerConnectionSummaryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchBrokerConnectionSummaryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchBrokerConnectionSummaryRequest {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: FetchBrokerConnectionSummaryRequest): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchBrokerConnectionSummaryRequest>, I>>(
    base?: I,
  ): FetchBrokerConnectionSummaryRequest {
    return FetchBrokerConnectionSummaryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchBrokerConnectionSummaryRequest>, I>>(
    object: I,
  ): FetchBrokerConnectionSummaryRequest {
    const message = createBaseFetchBrokerConnectionSummaryRequest();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseRefreshBrokerConnectionRequest(): RefreshBrokerConnectionRequest {
  return { brokerConnectionName: "" };
}

export const RefreshBrokerConnectionRequest = {
  encode(message: RefreshBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshBrokerConnectionRequest {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: RefreshBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshBrokerConnectionRequest>, I>>(base?: I): RefreshBrokerConnectionRequest {
    return RefreshBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshBrokerConnectionRequest>, I>>(
    object: I,
  ): RefreshBrokerConnectionRequest {
    const message = createBaseRefreshBrokerConnectionRequest();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseRefreshBrokerConnectionResponse(): RefreshBrokerConnectionResponse {
  return { refreshUrl: "" };
}

export const RefreshBrokerConnectionResponse = {
  encode(message: RefreshBrokerConnectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.refreshUrl !== "") {
      writer.uint32(10).string(message.refreshUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshBrokerConnectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshBrokerConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refreshUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshBrokerConnectionResponse {
    return { refreshUrl: isSet(object.refreshUrl) ? String(object.refreshUrl) : "" };
  },

  toJSON(message: RefreshBrokerConnectionResponse): unknown {
    const obj: any = {};
    if (message.refreshUrl !== "") {
      obj.refreshUrl = message.refreshUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshBrokerConnectionResponse>, I>>(base?: I): RefreshBrokerConnectionResponse {
    return RefreshBrokerConnectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshBrokerConnectionResponse>, I>>(
    object: I,
  ): RefreshBrokerConnectionResponse {
    const message = createBaseRefreshBrokerConnectionResponse();
    message.refreshUrl = object.refreshUrl ?? "";
    return message;
  },
};

function createBaseListBrokerConnectionsRequest(): ListBrokerConnectionsRequest {
  return { investorName: "", pageSize: 0, pageToken: "", brokerNames: [] };
}

export const ListBrokerConnectionsRequest = {
  encode(message: ListBrokerConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.investorName !== "") {
      writer.uint32(10).string(message.investorName);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(26).string(message.pageToken);
    }
    for (const v of message.brokerNames) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokerConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokerConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.investorName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.brokerNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokerConnectionsRequest {
    return {
      investorName: isSet(object.investorName) ? String(object.investorName) : "",
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      brokerNames: globalThis.Array.isArray(object?.brokerNames) ? object.brokerNames.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ListBrokerConnectionsRequest): unknown {
    const obj: any = {};
    if (message.investorName !== "") {
      obj.investorName = message.investorName;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.brokerNames?.length) {
      obj.brokerNames = message.brokerNames;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokerConnectionsRequest>, I>>(base?: I): ListBrokerConnectionsRequest {
    return ListBrokerConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokerConnectionsRequest>, I>>(object: I): ListBrokerConnectionsRequest {
    const message = createBaseListBrokerConnectionsRequest();
    message.investorName = object.investorName ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.brokerNames = object.brokerNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseListBrokerConnectionsResponse(): ListBrokerConnectionsResponse {
  return { brokerConnections: [], nextPageToken: "" };
}

export const ListBrokerConnectionsResponse = {
  encode(message: ListBrokerConnectionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerConnections) {
      BrokerConnectionWithBroker.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokerConnectionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokerConnectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnections.push(BrokerConnectionWithBroker.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokerConnectionsResponse {
    return {
      brokerConnections: globalThis.Array.isArray(object?.brokerConnections)
        ? object.brokerConnections.map((e: any) => BrokerConnectionWithBroker.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListBrokerConnectionsResponse): unknown {
    const obj: any = {};
    if (message.brokerConnections?.length) {
      obj.brokerConnections = message.brokerConnections.map((e) => BrokerConnectionWithBroker.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokerConnectionsResponse>, I>>(base?: I): ListBrokerConnectionsResponse {
    return ListBrokerConnectionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokerConnectionsResponse>, I>>(
    object: I,
  ): ListBrokerConnectionsResponse {
    const message = createBaseListBrokerConnectionsResponse();
    message.brokerConnections = object.brokerConnections?.map((e) => BrokerConnectionWithBroker.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseCreateVotableFundVariantRequest(): CreateVotableFundVariantRequest {
  return { fundVariantName: "" };
}

export const CreateVotableFundVariantRequest = {
  encode(message: CreateVotableFundVariantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fundVariantName !== "") {
      writer.uint32(10).string(message.fundVariantName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateVotableFundVariantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateVotableFundVariantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundVariantName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateVotableFundVariantRequest {
    return { fundVariantName: isSet(object.fundVariantName) ? String(object.fundVariantName) : "" };
  },

  toJSON(message: CreateVotableFundVariantRequest): unknown {
    const obj: any = {};
    if (message.fundVariantName !== "") {
      obj.fundVariantName = message.fundVariantName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateVotableFundVariantRequest>, I>>(base?: I): CreateVotableFundVariantRequest {
    return CreateVotableFundVariantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateVotableFundVariantRequest>, I>>(
    object: I,
  ): CreateVotableFundVariantRequest {
    const message = createBaseCreateVotableFundVariantRequest();
    message.fundVariantName = object.fundVariantName ?? "";
    return message;
  },
};

function createBaseDeleteVotableFundVariantRequest(): DeleteVotableFundVariantRequest {
  return { fundVariantName: "" };
}

export const DeleteVotableFundVariantRequest = {
  encode(message: DeleteVotableFundVariantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fundVariantName !== "") {
      writer.uint32(10).string(message.fundVariantName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteVotableFundVariantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteVotableFundVariantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundVariantName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteVotableFundVariantRequest {
    return { fundVariantName: isSet(object.fundVariantName) ? String(object.fundVariantName) : "" };
  },

  toJSON(message: DeleteVotableFundVariantRequest): unknown {
    const obj: any = {};
    if (message.fundVariantName !== "") {
      obj.fundVariantName = message.fundVariantName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteVotableFundVariantRequest>, I>>(base?: I): DeleteVotableFundVariantRequest {
    return DeleteVotableFundVariantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteVotableFundVariantRequest>, I>>(
    object: I,
  ): DeleteVotableFundVariantRequest {
    const message = createBaseDeleteVotableFundVariantRequest();
    message.fundVariantName = object.fundVariantName ?? "";
    return message;
  },
};

function createBaseUpdateBrokerConnectionRequest(): UpdateBrokerConnectionRequest {
  return { brokerConnection: undefined };
}

export const UpdateBrokerConnectionRequest = {
  encode(message: UpdateBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnection !== undefined) {
      BrokerConnection.encode(message.brokerConnection, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnection = BrokerConnection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBrokerConnectionRequest {
    return {
      brokerConnection: isSet(object.brokerConnection) ? BrokerConnection.fromJSON(object.brokerConnection) : undefined,
    };
  },

  toJSON(message: UpdateBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerConnection !== undefined) {
      obj.brokerConnection = BrokerConnection.toJSON(message.brokerConnection);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBrokerConnectionRequest>, I>>(base?: I): UpdateBrokerConnectionRequest {
    return UpdateBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBrokerConnectionRequest>, I>>(
    object: I,
  ): UpdateBrokerConnectionRequest {
    const message = createBaseUpdateBrokerConnectionRequest();
    message.brokerConnection = (object.brokerConnection !== undefined && object.brokerConnection !== null)
      ? BrokerConnection.fromPartial(object.brokerConnection)
      : undefined;
    return message;
  },
};

function createBaseConnectionStats(): ConnectionStats {
  return { staleConnectionCount: 0, staleConnectionsByBroker: {}, brokerConnectionStatuses: [] };
}

export const ConnectionStats = {
  encode(message: ConnectionStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.staleConnectionCount !== 0) {
      writer.uint32(8).int64(message.staleConnectionCount);
    }
    Object.entries(message.staleConnectionsByBroker).forEach(([key, value]) => {
      ConnectionStats_StaleConnectionsByBrokerEntry.encode({ key: key as any, value }, writer.uint32(26).fork())
        .ldelim();
    });
    for (const v of message.brokerConnectionStatuses) {
      ConnectionStats_BrokerConnectionStatus.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConnectionStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConnectionStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.staleConnectionCount = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = ConnectionStats_StaleConnectionsByBrokerEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.staleConnectionsByBroker[entry3.key] = entry3.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokerConnectionStatuses.push(ConnectionStats_BrokerConnectionStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConnectionStats {
    return {
      staleConnectionCount: isSet(object.staleConnectionCount) ? Number(object.staleConnectionCount) : 0,
      staleConnectionsByBroker: isObject(object.staleConnectionsByBroker)
        ? Object.entries(object.staleConnectionsByBroker).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      brokerConnectionStatuses: globalThis.Array.isArray(object?.brokerConnectionStatuses)
        ? object.brokerConnectionStatuses.map((e: any) => ConnectionStats_BrokerConnectionStatus.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ConnectionStats): unknown {
    const obj: any = {};
    if (message.staleConnectionCount !== 0) {
      obj.staleConnectionCount = Math.round(message.staleConnectionCount);
    }
    if (message.staleConnectionsByBroker) {
      const entries = Object.entries(message.staleConnectionsByBroker);
      if (entries.length > 0) {
        obj.staleConnectionsByBroker = {};
        entries.forEach(([k, v]) => {
          obj.staleConnectionsByBroker[k] = Math.round(v);
        });
      }
    }
    if (message.brokerConnectionStatuses?.length) {
      obj.brokerConnectionStatuses = message.brokerConnectionStatuses.map((e) =>
        ConnectionStats_BrokerConnectionStatus.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConnectionStats>, I>>(base?: I): ConnectionStats {
    return ConnectionStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConnectionStats>, I>>(object: I): ConnectionStats {
    const message = createBaseConnectionStats();
    message.staleConnectionCount = object.staleConnectionCount ?? 0;
    message.staleConnectionsByBroker = Object.entries(object.staleConnectionsByBroker ?? {}).reduce<
      { [key: string]: number }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Number(value);
      }
      return acc;
    }, {});
    message.brokerConnectionStatuses =
      object.brokerConnectionStatuses?.map((e) => ConnectionStats_BrokerConnectionStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConnectionStats_BrokerConnectionStatus(): ConnectionStats_BrokerConnectionStatus {
  return { brokerTitle: "", statusCount: {} };
}

export const ConnectionStats_BrokerConnectionStatus = {
  encode(message: ConnectionStats_BrokerConnectionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerTitle !== "") {
      writer.uint32(26).string(message.brokerTitle);
    }
    Object.entries(message.statusCount).forEach(([key, value]) => {
      ConnectionStats_BrokerConnectionStatus_StatusCountEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConnectionStats_BrokerConnectionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConnectionStats_BrokerConnectionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerTitle = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = ConnectionStats_BrokerConnectionStatus_StatusCountEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.statusCount[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConnectionStats_BrokerConnectionStatus {
    return {
      brokerTitle: isSet(object.brokerTitle) ? String(object.brokerTitle) : "",
      statusCount: isObject(object.statusCount)
        ? Object.entries(object.statusCount).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ConnectionStats_BrokerConnectionStatus): unknown {
    const obj: any = {};
    if (message.brokerTitle !== "") {
      obj.brokerTitle = message.brokerTitle;
    }
    if (message.statusCount) {
      const entries = Object.entries(message.statusCount);
      if (entries.length > 0) {
        obj.statusCount = {};
        entries.forEach(([k, v]) => {
          obj.statusCount[k] = Math.round(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConnectionStats_BrokerConnectionStatus>, I>>(
    base?: I,
  ): ConnectionStats_BrokerConnectionStatus {
    return ConnectionStats_BrokerConnectionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConnectionStats_BrokerConnectionStatus>, I>>(
    object: I,
  ): ConnectionStats_BrokerConnectionStatus {
    const message = createBaseConnectionStats_BrokerConnectionStatus();
    message.brokerTitle = object.brokerTitle ?? "";
    message.statusCount = Object.entries(object.statusCount ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseConnectionStats_BrokerConnectionStatus_StatusCountEntry(): ConnectionStats_BrokerConnectionStatus_StatusCountEntry {
  return { key: "", value: 0 };
}

export const ConnectionStats_BrokerConnectionStatus_StatusCountEntry = {
  encode(
    message: ConnectionStats_BrokerConnectionStatus_StatusCountEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConnectionStats_BrokerConnectionStatus_StatusCountEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConnectionStats_BrokerConnectionStatus_StatusCountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConnectionStats_BrokerConnectionStatus_StatusCountEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: ConnectionStats_BrokerConnectionStatus_StatusCountEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConnectionStats_BrokerConnectionStatus_StatusCountEntry>, I>>(
    base?: I,
  ): ConnectionStats_BrokerConnectionStatus_StatusCountEntry {
    return ConnectionStats_BrokerConnectionStatus_StatusCountEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConnectionStats_BrokerConnectionStatus_StatusCountEntry>, I>>(
    object: I,
  ): ConnectionStats_BrokerConnectionStatus_StatusCountEntry {
    const message = createBaseConnectionStats_BrokerConnectionStatus_StatusCountEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseConnectionStats_StaleConnectionsByBrokerEntry(): ConnectionStats_StaleConnectionsByBrokerEntry {
  return { key: "", value: 0 };
}

export const ConnectionStats_StaleConnectionsByBrokerEntry = {
  encode(message: ConnectionStats_StaleConnectionsByBrokerEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConnectionStats_StaleConnectionsByBrokerEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConnectionStats_StaleConnectionsByBrokerEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConnectionStats_StaleConnectionsByBrokerEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: ConnectionStats_StaleConnectionsByBrokerEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConnectionStats_StaleConnectionsByBrokerEntry>, I>>(
    base?: I,
  ): ConnectionStats_StaleConnectionsByBrokerEntry {
    return ConnectionStats_StaleConnectionsByBrokerEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConnectionStats_StaleConnectionsByBrokerEntry>, I>>(
    object: I,
  ): ConnectionStats_StaleConnectionsByBrokerEntry {
    const message = createBaseConnectionStats_StaleConnectionsByBrokerEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

/** RetailAccountConnectorService provides functionality for connecting retail users with Brokers */
export interface RetailAccountConnectorService {
  /**
   * CreateBroker creates a Broker that can be connected to through a BrokerConnector
   *
   * Possible errors:
   * - AlreadyExists - if a broker with the same external_id already exists
   * - InvalidArgument - if a required field is missing or a field is invalid
   */
  CreateBroker(request: DeepPartial<CreateBrokerRequest>, metadata?: grpc.Metadata): Promise<Broker>;
  /** ListBrokers returns a paginated list of brokers matching the request */
  ListBrokers(request: DeepPartial<ListBrokersRequest>, metadata?: grpc.Metadata): Promise<ListBrokersResponse>;
  /** UpdateBroker updates the broker details like title, logo_url and is_active */
  UpdateBroker(request: DeepPartial<UpdateBrokerRequest>, metadata?: grpc.Metadata): Promise<Broker>;
  /** DeleteBroker deletes the broker */
  DeleteBroker(request: DeepPartial<DeleteBrokerRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * StartBrokerConnection generates the auth URL for a user to connect
   * their broker (eg. HL) via their broker connector (eg. Moneyhub)
   */
  StartBrokerConnection(
    request: DeepPartial<StartBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartBrokerConnectionResponse>;
  /** CompleteBrokerConnection is called when a user finished an auth journey with a broker in the retail account connector. */
  CompleteBrokerConnection(
    request: DeepPartial<CompleteBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BrokerConnection>;
  /** FetchBrokerConnectionSummary returns a summary of a broker connection, listing all the accounts & investments within those accounts */
  FetchBrokerConnectionSummary(
    request: DeepPartial<FetchBrokerConnectionSummaryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AccountsSummary>;
  /** DeleteBrokerConnections deletes the broker connection */
  DeleteBrokerConnection(request: DeepPartial<DeleteBrokerConnectionRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /** RefreshBrokerConnection returns a refresh/reauth url for the investor */
  RefreshBrokerConnection(
    request: DeepPartial<RefreshBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshBrokerConnectionResponse>;
  /** SyncRetailAccounts syncs the retail accounts from Moneyhub to the transparency API */
  SyncRetailAccounts(
    request: DeepPartial<SyncRetailAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BrokerConnection>;
  /** ListBrokerConnections list broker connections for user to see the brokers which they have connected */
  ListBrokerConnections(
    request: DeepPartial<ListBrokerConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListBrokerConnectionsResponse>;
  /** CreateVotableFundVariant allows a FundVariant to be Pass Through Votable */
  CreateVotableFundVariant(
    request: DeepPartial<CreateVotableFundVariantRequest>,
    metadata?: grpc.Metadata,
  ): Promise<VotableFundVariant>;
  /** DeleteVotableFundVariant removes a FundVariant from being Pass Through Votable */
  DeleteVotableFundVariant(
    request: DeepPartial<DeleteVotableFundVariantRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  /**
   * UpdateBrokerConnection updates an existing broker connection with a patch
   * Only these fields can be updated:
   * - account_sync_status
   * - account_sync_status_update_time
   */
  UpdateBrokerConnection(
    request: DeepPartial<UpdateBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BrokerConnection>;
  /**
   * FetchConnectionStats returns the connection stats for broker connections.
   *
   * The stats include:
   * - stale_connection_count: the number of connections that have a SUCCESSFUL sync status, but have not been
   *   successfully synced within the last 24 hours.
   * - broker_connection_statuses: a list of connection status counts for each broker.
   * buf:lint:ignore RPC_REQUEST_STANDARD_NAME
   */
  FetchConnectionStats(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ConnectionStats>;
}

export class RetailAccountConnectorServiceClientImpl implements RetailAccountConnectorService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateBroker = this.CreateBroker.bind(this);
    this.ListBrokers = this.ListBrokers.bind(this);
    this.UpdateBroker = this.UpdateBroker.bind(this);
    this.DeleteBroker = this.DeleteBroker.bind(this);
    this.StartBrokerConnection = this.StartBrokerConnection.bind(this);
    this.CompleteBrokerConnection = this.CompleteBrokerConnection.bind(this);
    this.FetchBrokerConnectionSummary = this.FetchBrokerConnectionSummary.bind(this);
    this.DeleteBrokerConnection = this.DeleteBrokerConnection.bind(this);
    this.RefreshBrokerConnection = this.RefreshBrokerConnection.bind(this);
    this.SyncRetailAccounts = this.SyncRetailAccounts.bind(this);
    this.ListBrokerConnections = this.ListBrokerConnections.bind(this);
    this.CreateVotableFundVariant = this.CreateVotableFundVariant.bind(this);
    this.DeleteVotableFundVariant = this.DeleteVotableFundVariant.bind(this);
    this.UpdateBrokerConnection = this.UpdateBrokerConnection.bind(this);
    this.FetchConnectionStats = this.FetchConnectionStats.bind(this);
  }

  CreateBroker(request: DeepPartial<CreateBrokerRequest>, metadata?: grpc.Metadata): Promise<Broker> {
    return this.rpc.unary(
      RetailAccountConnectorServiceCreateBrokerDesc,
      CreateBrokerRequest.fromPartial(request),
      metadata,
    );
  }

  ListBrokers(request: DeepPartial<ListBrokersRequest>, metadata?: grpc.Metadata): Promise<ListBrokersResponse> {
    return this.rpc.unary(
      RetailAccountConnectorServiceListBrokersDesc,
      ListBrokersRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateBroker(request: DeepPartial<UpdateBrokerRequest>, metadata?: grpc.Metadata): Promise<Broker> {
    return this.rpc.unary(
      RetailAccountConnectorServiceUpdateBrokerDesc,
      UpdateBrokerRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteBroker(request: DeepPartial<DeleteBrokerRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      RetailAccountConnectorServiceDeleteBrokerDesc,
      DeleteBrokerRequest.fromPartial(request),
      metadata,
    );
  }

  StartBrokerConnection(
    request: DeepPartial<StartBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartBrokerConnectionResponse> {
    return this.rpc.unary(
      RetailAccountConnectorServiceStartBrokerConnectionDesc,
      StartBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  CompleteBrokerConnection(
    request: DeepPartial<CompleteBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BrokerConnection> {
    return this.rpc.unary(
      RetailAccountConnectorServiceCompleteBrokerConnectionDesc,
      CompleteBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  FetchBrokerConnectionSummary(
    request: DeepPartial<FetchBrokerConnectionSummaryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AccountsSummary> {
    return this.rpc.unary(
      RetailAccountConnectorServiceFetchBrokerConnectionSummaryDesc,
      FetchBrokerConnectionSummaryRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteBrokerConnection(
    request: DeepPartial<DeleteBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      RetailAccountConnectorServiceDeleteBrokerConnectionDesc,
      DeleteBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  RefreshBrokerConnection(
    request: DeepPartial<RefreshBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshBrokerConnectionResponse> {
    return this.rpc.unary(
      RetailAccountConnectorServiceRefreshBrokerConnectionDesc,
      RefreshBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  SyncRetailAccounts(
    request: DeepPartial<SyncRetailAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BrokerConnection> {
    return this.rpc.unary(
      RetailAccountConnectorServiceSyncRetailAccountsDesc,
      SyncRetailAccountsRequest.fromPartial(request),
      metadata,
    );
  }

  ListBrokerConnections(
    request: DeepPartial<ListBrokerConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListBrokerConnectionsResponse> {
    return this.rpc.unary(
      RetailAccountConnectorServiceListBrokerConnectionsDesc,
      ListBrokerConnectionsRequest.fromPartial(request),
      metadata,
    );
  }

  CreateVotableFundVariant(
    request: DeepPartial<CreateVotableFundVariantRequest>,
    metadata?: grpc.Metadata,
  ): Promise<VotableFundVariant> {
    return this.rpc.unary(
      RetailAccountConnectorServiceCreateVotableFundVariantDesc,
      CreateVotableFundVariantRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteVotableFundVariant(
    request: DeepPartial<DeleteVotableFundVariantRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      RetailAccountConnectorServiceDeleteVotableFundVariantDesc,
      DeleteVotableFundVariantRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateBrokerConnection(
    request: DeepPartial<UpdateBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BrokerConnection> {
    return this.rpc.unary(
      RetailAccountConnectorServiceUpdateBrokerConnectionDesc,
      UpdateBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  FetchConnectionStats(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ConnectionStats> {
    return this.rpc.unary(RetailAccountConnectorServiceFetchConnectionStatsDesc, Empty.fromPartial(request), metadata);
  }
}

export const RetailAccountConnectorServiceDesc = {
  serviceName: "tumelo.retailaccountconnector.v1.RetailAccountConnectorService",
};

export const RetailAccountConnectorServiceCreateBrokerDesc: UnaryMethodDefinitionish = {
  methodName: "CreateBroker",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBrokerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Broker.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceListBrokersDesc: UnaryMethodDefinitionish = {
  methodName: "ListBrokers",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListBrokersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListBrokersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceUpdateBrokerDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateBroker",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateBrokerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Broker.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceDeleteBrokerDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteBroker",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteBrokerRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceStartBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "StartBrokerConnection",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartBrokerConnectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceCompleteBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "CompleteBrokerConnection",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CompleteBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BrokerConnection.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceFetchBrokerConnectionSummaryDesc: UnaryMethodDefinitionish = {
  methodName: "FetchBrokerConnectionSummary",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchBrokerConnectionSummaryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AccountsSummary.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceDeleteBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteBrokerConnection",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceRefreshBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "RefreshBrokerConnection",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefreshBrokerConnectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceSyncRetailAccountsDesc: UnaryMethodDefinitionish = {
  methodName: "SyncRetailAccounts",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SyncRetailAccountsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BrokerConnection.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceListBrokerConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "ListBrokerConnections",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListBrokerConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListBrokerConnectionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceCreateVotableFundVariantDesc: UnaryMethodDefinitionish = {
  methodName: "CreateVotableFundVariant",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateVotableFundVariantRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = VotableFundVariant.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceDeleteVotableFundVariantDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteVotableFundVariant",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteVotableFundVariantRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceUpdateBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateBrokerConnection",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BrokerConnection.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailAccountConnectorServiceFetchConnectionStatsDesc: UnaryMethodDefinitionish = {
  methodName: "FetchConnectionStats",
  service: RetailAccountConnectorServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ConnectionStats.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
