/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Broker } from "./broker";

export const protobufPackage = "tumelo.retailaccountconnector.v1";

/** AccountsSummary is the top-level summary of all the accounts blonging to a broker-connection. */
export interface AccountsSummary {
  /** REQUIRED broker which the account summary is for */
  broker:
    | Broker
    | undefined;
  /** REQUIRED list of accounts associated with the broker */
  accounts: AccountSummary[];
}

/**
 * AccountSummary is a top-level summary of investment information for a specific account.
 * Each account summary can have many investments.
 */
export interface AccountSummary {
  /** REQUIRED title of the account used by the broker */
  accountTitle: string;
  /** REQUIRED list of investments held in the account */
  investments: InvestmentSummary[];
}

/**
 * InvestmentSummary is the top-level summary of an account's investments.
 * It is pretty minimal and only contains the investment title and whether it is votable.
 */
export interface InvestmentSummary {
  /** REQUIRED title used by broker for the investment */
  investmentTitle: string;
  /** REQUIRED is the investment pass through votable */
  isPassthroughVotable: boolean;
}

function createBaseAccountsSummary(): AccountsSummary {
  return { broker: undefined, accounts: [] };
}

export const AccountsSummary = {
  encode(message: AccountsSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.broker !== undefined) {
      Broker.encode(message.broker, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.accounts) {
      AccountSummary.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountsSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountsSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.broker = Broker.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accounts.push(AccountSummary.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountsSummary {
    return {
      broker: isSet(object.broker) ? Broker.fromJSON(object.broker) : undefined,
      accounts: globalThis.Array.isArray(object?.accounts)
        ? object.accounts.map((e: any) => AccountSummary.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountsSummary): unknown {
    const obj: any = {};
    if (message.broker !== undefined) {
      obj.broker = Broker.toJSON(message.broker);
    }
    if (message.accounts?.length) {
      obj.accounts = message.accounts.map((e) => AccountSummary.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountsSummary>, I>>(base?: I): AccountsSummary {
    return AccountsSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountsSummary>, I>>(object: I): AccountsSummary {
    const message = createBaseAccountsSummary();
    message.broker = (object.broker !== undefined && object.broker !== null)
      ? Broker.fromPartial(object.broker)
      : undefined;
    message.accounts = object.accounts?.map((e) => AccountSummary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountSummary(): AccountSummary {
  return { accountTitle: "", investments: [] };
}

export const AccountSummary = {
  encode(message: AccountSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountTitle !== "") {
      writer.uint32(10).string(message.accountTitle);
    }
    for (const v of message.investments) {
      InvestmentSummary.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountTitle = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.investments.push(InvestmentSummary.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSummary {
    return {
      accountTitle: isSet(object.accountTitle) ? String(object.accountTitle) : "",
      investments: globalThis.Array.isArray(object?.investments)
        ? object.investments.map((e: any) => InvestmentSummary.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountSummary): unknown {
    const obj: any = {};
    if (message.accountTitle !== "") {
      obj.accountTitle = message.accountTitle;
    }
    if (message.investments?.length) {
      obj.investments = message.investments.map((e) => InvestmentSummary.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSummary>, I>>(base?: I): AccountSummary {
    return AccountSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSummary>, I>>(object: I): AccountSummary {
    const message = createBaseAccountSummary();
    message.accountTitle = object.accountTitle ?? "";
    message.investments = object.investments?.map((e) => InvestmentSummary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInvestmentSummary(): InvestmentSummary {
  return { investmentTitle: "", isPassthroughVotable: false };
}

export const InvestmentSummary = {
  encode(message: InvestmentSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.investmentTitle !== "") {
      writer.uint32(10).string(message.investmentTitle);
    }
    if (message.isPassthroughVotable === true) {
      writer.uint32(16).bool(message.isPassthroughVotable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvestmentSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvestmentSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.investmentTitle = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isPassthroughVotable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvestmentSummary {
    return {
      investmentTitle: isSet(object.investmentTitle) ? String(object.investmentTitle) : "",
      isPassthroughVotable: isSet(object.isPassthroughVotable) ? Boolean(object.isPassthroughVotable) : false,
    };
  },

  toJSON(message: InvestmentSummary): unknown {
    const obj: any = {};
    if (message.investmentTitle !== "") {
      obj.investmentTitle = message.investmentTitle;
    }
    if (message.isPassthroughVotable === true) {
      obj.isPassthroughVotable = message.isPassthroughVotable;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InvestmentSummary>, I>>(base?: I): InvestmentSummary {
    return InvestmentSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InvestmentSummary>, I>>(object: I): InvestmentSummary {
    const message = createBaseInvestmentSummary();
    message.investmentTitle = object.investmentTitle ?? "";
    message.isPassthroughVotable = object.isPassthroughVotable ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
