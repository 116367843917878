import { BrokerConnection } from '@/application/domain/brokerConnection'
import {
  CompleteBrokerConnectionResponse,
  FetchAvailableVotingPoliciesResponse,
  GetSelectedVotingPolicyResponse,
  ListBrokerConnectionsResponse,
  ListBrokersResponse,
  StartBrokerConnectionResponse,
  ListHistoricalVotesResponse,
  RefreshBrokerConnectionResponse,
} from '@/application/proto/tumelo/retailbff/v1/service'
import { AccountsSummary } from '@/application/proto/tumelo/retailbff/v1/account'
import { AjBell, CharlesSchwab, Fidelity, HargreavesLansdown, Vanguard } from './broker'
import { SampleVotingPolicy } from './votingpolicy'
import { AJBellBrokerConnection, CharlesSchwabBrokerConnection, FidelityBrokerConnection } from './brokerConnection'
import { SampleAccountsSummary } from './account'
import {
  sampleHistoricalVote,
  historicalVoteWithAbstain,
  historicalVoteWithNoAction,
  historicalVoteWithOneYearDecision,
  historicalVoteWithMultipleThemeCodes,
  historicalVoteWithWithhold,
  historicalVoteWithCustomThemeCodes,
  historicalVoteWithUndefinedThemeCodes,
} from './historicalVote'

export const SampleCompleteBrokerConnectionResponse = (
  override?: Partial<CompleteBrokerConnectionResponse>
): CompleteBrokerConnectionResponse =>
  CompleteBrokerConnectionResponse.fromPartial({
    ...override,
  })

export const SampleListBrokersResponse = (override?: Partial<ListBrokersResponse>): ListBrokersResponse =>
  ListBrokersResponse.fromPartial({
    brokers: [AjBell, CharlesSchwab, Fidelity, HargreavesLansdown, Vanguard],
    ...override,
  })

export const SampleStartBrokerConnectionResponse = (
  override?: Partial<StartBrokerConnectionResponse>
): StartBrokerConnectionResponse =>
  StartBrokerConnectionResponse.fromPartial({
    url: '/broker-selection/complete-connection/mock-broker',
    ...override,
  })

export const SampleGetInvestorVotingPolicyResponse = (
  override?: Partial<GetSelectedVotingPolicyResponse>
): GetSelectedVotingPolicyResponse =>
  GetSelectedVotingPolicyResponse.fromPartial({ votingPolicy: SampleVotingPolicy(), ...override })

export const SampleListBrokerConnectionsResponse = (
  override?: Partial<ListBrokerConnectionsResponse>
): ListBrokerConnectionsResponse =>
  ListBrokerConnectionsResponse.fromPartial({
    brokers: [AJBellBrokerConnection, CharlesSchwabBrokerConnection, FidelityBrokerConnection],
    ...override,
  })

export const DomainSampleListBrokerConnectionsResponse = (): BrokerConnection[] => [
  AJBellBrokerConnection,
  CharlesSchwabBrokerConnection,
]

export const SampleFetchAvailableVotingPoliciesResponse = (
  override?: Partial<FetchAvailableVotingPoliciesResponse>
): FetchAvailableVotingPoliciesResponse =>
  FetchAvailableVotingPoliciesResponse.fromPartial({
    votingPolicies: [
      SampleVotingPolicy({ title: 'Policy 1', name: 'policyId1' }),
      SampleVotingPolicy({ title: 'Policy 2', name: 'policyId2' }),
      SampleVotingPolicy({ title: 'Policy 3', name: 'policyId3' }),
      SampleVotingPolicy({ title: 'Policy 4', name: 'policyId4' }),
      SampleVotingPolicy({ title: 'Policy 5', name: 'policyId5' }),
      SampleVotingPolicy({ title: 'Policy 6', name: 'policyId6' }),
    ],
    ...override,
  })

export const SampleAccountsSummaryMap = new Map<string, AccountsSummary>([
  [HargreavesLansdown.name, SampleAccountsSummary({ broker: HargreavesLansdown })],
  [AjBell.name, SampleAccountsSummary({ broker: AjBell })],
  [Vanguard.name, SampleAccountsSummary({ broker: Vanguard })],
  [CharlesSchwab.name, SampleAccountsSummary({ broker: CharlesSchwab })],
  [AJBellBrokerConnection.name, SampleAccountsSummary({ broker: AjBell })],
  [CharlesSchwabBrokerConnection.name, SampleAccountsSummary({ broker: CharlesSchwab })],
])

export const SampleListHistoricalVotesResponse = (
  override?: Partial<ListHistoricalVotesResponse>
): ListHistoricalVotesResponse =>
  ListHistoricalVotesResponse.fromPartial({
    historicalVotes: [
      sampleHistoricalVote,
      historicalVoteWithAbstain,
      historicalVoteWithNoAction,
      historicalVoteWithOneYearDecision,
      historicalVoteWithMultipleThemeCodes,
      historicalVoteWithWithhold,
      historicalVoteWithCustomThemeCodes,
      historicalVoteWithUndefinedThemeCodes,
    ],
    ...override,
  })

export const SampleRefreshBrokerConnectionResponse = (
  override?: Partial<RefreshBrokerConnectionResponse>
): RefreshBrokerConnectionResponse =>
  RefreshBrokerConnectionResponse.fromPartial({
    refreshUrl: '/broker-selection/refresh-connection/mock-broker',
    ...override,
  })
