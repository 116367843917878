import {
  HistoricalVote,
  HistoricalVote_VoteOption,
  HistoricalVote_Outcome_Decision,
} from '@/application/proto/tumelo/retailbff/v1/historical_votes'

export const sampleHistoricalVote: HistoricalVote = {
  name: 'organizations/1234/generalMeetings/5678/proposals/91011',
  title:
    'Proposal for the Appointment of a New Chief Executive Officer to Lead the Company in Strategic Directions and Future Growth Initiatives',
  organization: {
    name: 'organizations/1234',
    title: 'Tech Corp',
  },
  meetingDate: new Date('2023-01-25T10:00:00Z'),
  themeCodes: ['AW', 'AU'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_FOR,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_FOR,
  },
}

export const historicalVoteWithAbstain: HistoricalVote = {
  name: 'organizations/2345/generalMeetings/6789/proposals/11213',
  title:
    'Proposal for the Relocation of the Office Headquarters to a New Location to Enhance Operational Efficiency and Accessibility for Stakeholders',
  organization: {
    name: 'organizations/1234',
    title: 'Tech Corp',
  },
  meetingDate: new Date('2023-01-25T10:00:00Z'),
  themeCodes: ['CS', 'CC'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_ABSTAIN,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_WITHDRAWN,
  },
}

export const historicalVoteWithNoAction: HistoricalVote = {
  name: 'organizations/3456/generalMeetings/7890/proposals/13141',
  title:
    'Proposal for Increasing the Dividend Payout to Shareholders as Part of the Company’s Long-term Financial Strategy and Commitment to Return Value',
  organization: {
    name: 'organizations/3456',
    title: 'Finance Group',
  },
  meetingDate: new Date('2023-03-20T09:30:00Z'),
  themeCodes: ['HE', 'OS'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_NO_ACTION,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_AGAINST,
  },
}

export const historicalVoteWithOneYearDecision: HistoricalVote = {
  name: 'organizations/4567/generalMeetings/8901/proposals/14152',
  title: 'Proposal for the Approval and Publication of the Annual Financial and Performance Report for the Fiscal Year',
  organization: {
    name: 'organizations/4567',
    title: 'Consulting LLC',
  },
  meetingDate: new Date('2023-05-10T11:00:00Z'),
  themeCodes: ['CT', 'AU'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_ONE_YEAR,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_ONE_YEAR,
  },
}

export const historicalVoteWithMultipleThemeCodes: HistoricalVote = {
  name: 'organizations/6789/generalMeetings/0123/proposals/16174',
  title:
    'Proposal for the Launch of a New Product Line to Expand Market Reach and Drive Revenue Growth in the Upcoming Fiscal Year',
  organization: {
    name: 'organizations/6789',
    title: 'Innovation Inc',
  },
  meetingDate: new Date('2023-08-25T10:30:00Z'),
  themeCodes: ['HE', 'PA', 'OE'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_AGAINST,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_AGAINST,
  },
}

export const historicalVoteWithWithhold: HistoricalVote = {
  name: 'organizations/7890/generalMeetings/1234/proposals/17185',
  title:
    'Proposal for the Enhancement of Employee Benefits and Compensation Packages to Improve Retention and Satisfaction',
  organization: {
    name: 'organizations/6789',
    title: 'Innovation Inc',
  },
  meetingDate: new Date('2023-08-25T10:30:00Z'),
  themeCodes: ['LO', 'BE'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_WITHHOLD,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_INVALID,
  },
}

export const historicalVoteWithCustomThemeCodes: HistoricalVote = {
  name: 'organizations/8901/generalMeetings/2345/proposals/18196',
  title:
    'Proposal for the Upgrade and Modernization of the IT Infrastructure to Support Enhanced Security and Operational Efficiency',
  organization: {
    name: 'organizations/6789',
    title: 'Innovation Inc',
  },
  meetingDate: new Date('2023-08-25T10:30:00Z'),
  themeCodes: ['PA', 'SC'],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_TWO_YEARS,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_TWO_YEARS,
  },
}

export const historicalVoteWithUndefinedThemeCodes: HistoricalVote = {
  name: 'organizations/9012/generalMeetings/3456/proposals/19207',
  title:
    'Proposal for the Development and Implementation of a Comprehensive Marketing Strategy to Enhance Brand Awareness and Market Penetration',
  organization: {
    name: 'organizations/9012',
    title: 'Market Leaders',
  },
  meetingDate: new Date('2023-10-10T14:30:00Z'),
  themeCodes: [],
  votingPolicy: {
    name: 'votingPolicies/7890',
    title: 'Climate Policy',
  },
  response: HistoricalVote_VoteOption.VOTE_OPTION_THREE_YEARS,
  outcome: {
    decision: HistoricalVote_Outcome_Decision.DECISION_THREE_YEARS,
  },
}
