/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Broker } from "./broker";

export const protobufPackage = "tumelo.retailaccountconnector.v1";

/** AccountSyncStates come from Moneyhub - see https://docs.moneyhubenterprise.com/reference/get_users-userid-connections */
export enum AccountSyncStatus {
  ACCOUNT_SYNC_STATUS_INVALID = 0,
  ACCOUNT_SYNC_STATUS_UNSYNCED = 1,
  ACCOUNT_SYNC_STATUS_SUCCESSFUL = 2,
  ACCOUNT_SYNC_STATUS_ERROR_RESYNC = 3,
  ACCOUNT_SYNC_STATUS_ERROR_SYNC = 4,
  ACCOUNT_SYNC_STATUS_ERROR_SYNC_PARTIAL = 5,
  ACCOUNT_SYNC_STATUS_ERROR_MFA_REQUIRED = 6,
  ACCOUNT_SYNC_STATUS_ERROR_CREDENTIALS = 7,
  ACCOUNT_SYNC_STATUS_ERROR_EXPIRED = 8,
  ACCOUNT_SYNC_STATUS_ERROR_SYNC_STOPPED = 9,
  ACCOUNT_SYNC_STATUS_ERROR_PERMANENTLY_REMOVED = 10,
  ACCOUNT_SYNC_STATUS_ERROR_DUPLICATE_ACCOUNT = 11,
  UNRECOGNIZED = -1,
}

export function accountSyncStatusFromJSON(object: any): AccountSyncStatus {
  switch (object) {
    case 0:
    case "ACCOUNT_SYNC_STATUS_INVALID":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_INVALID;
    case 1:
    case "ACCOUNT_SYNC_STATUS_UNSYNCED":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_UNSYNCED;
    case 2:
    case "ACCOUNT_SYNC_STATUS_SUCCESSFUL":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_SUCCESSFUL;
    case 3:
    case "ACCOUNT_SYNC_STATUS_ERROR_RESYNC":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_RESYNC;
    case 4:
    case "ACCOUNT_SYNC_STATUS_ERROR_SYNC":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_SYNC;
    case 5:
    case "ACCOUNT_SYNC_STATUS_ERROR_SYNC_PARTIAL":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_SYNC_PARTIAL;
    case 6:
    case "ACCOUNT_SYNC_STATUS_ERROR_MFA_REQUIRED":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_MFA_REQUIRED;
    case 7:
    case "ACCOUNT_SYNC_STATUS_ERROR_CREDENTIALS":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_CREDENTIALS;
    case 8:
    case "ACCOUNT_SYNC_STATUS_ERROR_EXPIRED":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_EXPIRED;
    case 9:
    case "ACCOUNT_SYNC_STATUS_ERROR_SYNC_STOPPED":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_SYNC_STOPPED;
    case 10:
    case "ACCOUNT_SYNC_STATUS_ERROR_PERMANENTLY_REMOVED":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_PERMANENTLY_REMOVED;
    case 11:
    case "ACCOUNT_SYNC_STATUS_ERROR_DUPLICATE_ACCOUNT":
      return AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_DUPLICATE_ACCOUNT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountSyncStatus.UNRECOGNIZED;
  }
}

export function accountSyncStatusToJSON(object: AccountSyncStatus): string {
  switch (object) {
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_INVALID:
      return "ACCOUNT_SYNC_STATUS_INVALID";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_UNSYNCED:
      return "ACCOUNT_SYNC_STATUS_UNSYNCED";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_SUCCESSFUL:
      return "ACCOUNT_SYNC_STATUS_SUCCESSFUL";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_RESYNC:
      return "ACCOUNT_SYNC_STATUS_ERROR_RESYNC";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_SYNC:
      return "ACCOUNT_SYNC_STATUS_ERROR_SYNC";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_SYNC_PARTIAL:
      return "ACCOUNT_SYNC_STATUS_ERROR_SYNC_PARTIAL";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_MFA_REQUIRED:
      return "ACCOUNT_SYNC_STATUS_ERROR_MFA_REQUIRED";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_CREDENTIALS:
      return "ACCOUNT_SYNC_STATUS_ERROR_CREDENTIALS";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_EXPIRED:
      return "ACCOUNT_SYNC_STATUS_ERROR_EXPIRED";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_SYNC_STOPPED:
      return "ACCOUNT_SYNC_STATUS_ERROR_SYNC_STOPPED";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_PERMANENTLY_REMOVED:
      return "ACCOUNT_SYNC_STATUS_ERROR_PERMANENTLY_REMOVED";
    case AccountSyncStatus.ACCOUNT_SYNC_STATUS_ERROR_DUPLICATE_ACCOUNT:
      return "ACCOUNT_SYNC_STATUS_ERROR_DUPLICATE_ACCOUNT";
    case AccountSyncStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface BrokerConnection {
  /** GENERATED name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  name: string;
  /** REQUIRED broker_name of the broker the connection is for */
  brokerName: string;
  /** REQUIRED external_id used by broker connector to identify this connnection */
  externalId: string;
  /** OPTIONAL account_sync_status status of last sync for this connection */
  accountSyncStatus: AccountSyncStatus;
  /** OPTIONAL time of last sync */
  accountSyncStatusUpdateTime: Date | undefined;
  createTime: Date | undefined;
  updateTime: Date | undefined;
}

export interface BrokerConnectionWithBroker {
  name: string;
  brokerConnectionStatus: AccountSyncStatus;
  externalId: string;
  createTime: Date | undefined;
  updateTime: Date | undefined;
  broker: Broker | undefined;
}

function createBaseBrokerConnection(): BrokerConnection {
  return {
    name: "",
    brokerName: "",
    externalId: "",
    accountSyncStatus: 0,
    accountSyncStatusUpdateTime: undefined,
    createTime: undefined,
    updateTime: undefined,
  };
}

export const BrokerConnection = {
  encode(message: BrokerConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.brokerName !== "") {
      writer.uint32(18).string(message.brokerName);
    }
    if (message.externalId !== "") {
      writer.uint32(26).string(message.externalId);
    }
    if (message.accountSyncStatus !== 0) {
      writer.uint32(32).int32(message.accountSyncStatus);
    }
    if (message.accountSyncStatusUpdateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.accountSyncStatusUpdateTime), writer.uint32(42).fork()).ldelim();
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokerName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.accountSyncStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountSyncStatusUpdateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrokerConnection {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      brokerName: isSet(object.brokerName) ? String(object.brokerName) : "",
      externalId: isSet(object.externalId) ? String(object.externalId) : "",
      accountSyncStatus: isSet(object.accountSyncStatus) ? accountSyncStatusFromJSON(object.accountSyncStatus) : 0,
      accountSyncStatusUpdateTime: isSet(object.accountSyncStatusUpdateTime)
        ? fromJsonTimestamp(object.accountSyncStatusUpdateTime)
        : undefined,
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
    };
  },

  toJSON(message: BrokerConnection): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.brokerName !== "") {
      obj.brokerName = message.brokerName;
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.accountSyncStatus !== 0) {
      obj.accountSyncStatus = accountSyncStatusToJSON(message.accountSyncStatus);
    }
    if (message.accountSyncStatusUpdateTime !== undefined) {
      obj.accountSyncStatusUpdateTime = message.accountSyncStatusUpdateTime.toISOString();
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerConnection>, I>>(base?: I): BrokerConnection {
    return BrokerConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerConnection>, I>>(object: I): BrokerConnection {
    const message = createBaseBrokerConnection();
    message.name = object.name ?? "";
    message.brokerName = object.brokerName ?? "";
    message.externalId = object.externalId ?? "";
    message.accountSyncStatus = object.accountSyncStatus ?? 0;
    message.accountSyncStatusUpdateTime = object.accountSyncStatusUpdateTime ?? undefined;
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    return message;
  },
};

function createBaseBrokerConnectionWithBroker(): BrokerConnectionWithBroker {
  return {
    name: "",
    brokerConnectionStatus: 0,
    externalId: "",
    createTime: undefined,
    updateTime: undefined,
    broker: undefined,
  };
}

export const BrokerConnectionWithBroker = {
  encode(message: BrokerConnectionWithBroker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.brokerConnectionStatus !== 0) {
      writer.uint32(16).int32(message.brokerConnectionStatus);
    }
    if (message.externalId !== "") {
      writer.uint32(26).string(message.externalId);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(42).fork()).ldelim();
    }
    if (message.broker !== undefined) {
      Broker.encode(message.broker, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerConnectionWithBroker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerConnectionWithBroker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.brokerConnectionStatus = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.broker = Broker.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrokerConnectionWithBroker {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      brokerConnectionStatus: isSet(object.brokerConnectionStatus)
        ? accountSyncStatusFromJSON(object.brokerConnectionStatus)
        : 0,
      externalId: isSet(object.externalId) ? String(object.externalId) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      broker: isSet(object.broker) ? Broker.fromJSON(object.broker) : undefined,
    };
  },

  toJSON(message: BrokerConnectionWithBroker): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.brokerConnectionStatus !== 0) {
      obj.brokerConnectionStatus = accountSyncStatusToJSON(message.brokerConnectionStatus);
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.broker !== undefined) {
      obj.broker = Broker.toJSON(message.broker);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerConnectionWithBroker>, I>>(base?: I): BrokerConnectionWithBroker {
    return BrokerConnectionWithBroker.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerConnectionWithBroker>, I>>(object: I): BrokerConnectionWithBroker {
    const message = createBaseBrokerConnectionWithBroker();
    message.name = object.name ?? "";
    message.brokerConnectionStatus = object.brokerConnectionStatus ?? 0;
    message.externalId = object.externalId ?? "";
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.broker = (object.broker !== undefined && object.broker !== null)
      ? Broker.fromPartial(object.broker)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
