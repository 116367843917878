import React from 'react'
import { Stack } from '@tumelo/designsystem'
import Image from 'next/image'

export const AuthHeader: React.FC = () => {
  return (
    <Stack alignItems="center" mb="3.2rem">
      <Image alt="tumelo" src="/images/proxysphere_for_dark_bg.svg" width="406" height="41" />
    </Stack>
  )
}
