import React, { useEffect, useState } from 'react'
import { CookieBanner as Banner } from '@tumelo/designsystem'
import { useLocalStorage } from '@/utilities/useLocalStorage'

export enum CookieConsentValue {
  Accepted = 'accepted',
  Rejected = 'rejected',
  NotSet = '',
}

export const cookieConsentKey = 'cookieconsent'

interface CookieBannerProps {
  children: React.ReactNode
}

export const CookieBanner: React.FC<CookieBannerProps> = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useLocalStorage(cookieConsentKey, CookieConsentValue.NotSet)
  const [isMounted, setIsMounted] = useState(false)

  // Ensure the component only interacts with local storage after mounting
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const onClickAccept = () => {
    setCookieConsent(CookieConsentValue.Accepted)
  }
  const onClickReject = () => {
    setCookieConsent(CookieConsentValue.Rejected)
  }

  // Avoid rendering the banner until the component is mounted (prevents hydration errors)
  if (!isMounted) {
    return null
  }

  if (cookieConsent !== CookieConsentValue.NotSet) {
    return <>{children}</>
  }

  return (
    <Banner onClickAccept={onClickAccept} onClickReject={onClickReject}>
      {children}
    </Banner>
  )
}
