export const contactSupport = {
  onClick: () => {
    window.location.href = supportEmailMailto
  },
  text: 'Contact support',
}

export const supportEmail = 'help@tumelo.com'

export const supportEmailMailto = `mailto:${supportEmail}?subject=Support%20Request:%20Issue%20with%20[Feature/Functionality]&body=Hello%20Support%20Team,%0D%0A%0D%0AI%20am%20experiencing%20an%20issue%20with%20[Feature/Functionality].%20Below%20are%20the%20details:%0D%0A%0D%0A-%20Issue%20Description:%20[Describe%20the%20issue%20in%20detail]%0D%0A-%20Steps%20to%20Reproduce:%20[Steps%20to%20replicate%20the%20issue]%0D%0A-%20Expected%20Outcome:%20[What%20you%20expected%20to%20happen]%0D%0A-%20Actual%20Outcome:%20[What%20actually%20happened]%0D%0A%0D%0APlease%20let%20me%20know%20if%20you%20need%20any%20more%20information.%0D%0A%0D%0AThank%20you,%0D%0A[Your%20Name]`
