/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.retailbff.v1";

export interface HistoricalVote {
  /** REQUIRED The name of the proposal in the format organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid} */
  name: string;
  /** REQUIRED The title of the proposal */
  title: string;
  /** REQUIRED The organization that the meeting vote report is for */
  organization:
    | HistoricalVote_Organization
    | undefined;
  /** REQUIRED The date the meeting was held */
  meetingDate:
    | Date
    | undefined;
  /** OPTIONAL a list of the 2-letter classification codes (themes from EDI data feed) that apply to the proposal */
  themeCodes: string[];
  /** REQUIRED The name of the vote policy used to create the ballot response */
  votingPolicy:
    | HistoricalVote_VotingPolicy
    | undefined;
  /** REQUIRED The response on the ballot for the current proposal */
  response: HistoricalVote_VoteOption;
  /** REQUIRED The outcome of the proposal */
  outcome: HistoricalVote_Outcome | undefined;
}

export enum HistoricalVote_VoteOption {
  VOTE_OPTION_INVALID = 0,
  VOTE_OPTION_FOR = 1,
  VOTE_OPTION_AGAINST = 2,
  VOTE_OPTION_ABSTAIN = 3,
  VOTE_OPTION_WITHHOLD = 4,
  VOTE_OPTION_NO_ACTION = 5,
  VOTE_OPTION_ONE_YEAR = 6,
  VOTE_OPTION_TWO_YEARS = 7,
  VOTE_OPTION_THREE_YEARS = 8,
  UNRECOGNIZED = -1,
}

export function historicalVote_VoteOptionFromJSON(object: any): HistoricalVote_VoteOption {
  switch (object) {
    case 0:
    case "VOTE_OPTION_INVALID":
      return HistoricalVote_VoteOption.VOTE_OPTION_INVALID;
    case 1:
    case "VOTE_OPTION_FOR":
      return HistoricalVote_VoteOption.VOTE_OPTION_FOR;
    case 2:
    case "VOTE_OPTION_AGAINST":
      return HistoricalVote_VoteOption.VOTE_OPTION_AGAINST;
    case 3:
    case "VOTE_OPTION_ABSTAIN":
      return HistoricalVote_VoteOption.VOTE_OPTION_ABSTAIN;
    case 4:
    case "VOTE_OPTION_WITHHOLD":
      return HistoricalVote_VoteOption.VOTE_OPTION_WITHHOLD;
    case 5:
    case "VOTE_OPTION_NO_ACTION":
      return HistoricalVote_VoteOption.VOTE_OPTION_NO_ACTION;
    case 6:
    case "VOTE_OPTION_ONE_YEAR":
      return HistoricalVote_VoteOption.VOTE_OPTION_ONE_YEAR;
    case 7:
    case "VOTE_OPTION_TWO_YEARS":
      return HistoricalVote_VoteOption.VOTE_OPTION_TWO_YEARS;
    case 8:
    case "VOTE_OPTION_THREE_YEARS":
      return HistoricalVote_VoteOption.VOTE_OPTION_THREE_YEARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HistoricalVote_VoteOption.UNRECOGNIZED;
  }
}

export function historicalVote_VoteOptionToJSON(object: HistoricalVote_VoteOption): string {
  switch (object) {
    case HistoricalVote_VoteOption.VOTE_OPTION_INVALID:
      return "VOTE_OPTION_INVALID";
    case HistoricalVote_VoteOption.VOTE_OPTION_FOR:
      return "VOTE_OPTION_FOR";
    case HistoricalVote_VoteOption.VOTE_OPTION_AGAINST:
      return "VOTE_OPTION_AGAINST";
    case HistoricalVote_VoteOption.VOTE_OPTION_ABSTAIN:
      return "VOTE_OPTION_ABSTAIN";
    case HistoricalVote_VoteOption.VOTE_OPTION_WITHHOLD:
      return "VOTE_OPTION_WITHHOLD";
    case HistoricalVote_VoteOption.VOTE_OPTION_NO_ACTION:
      return "VOTE_OPTION_NO_ACTION";
    case HistoricalVote_VoteOption.VOTE_OPTION_ONE_YEAR:
      return "VOTE_OPTION_ONE_YEAR";
    case HistoricalVote_VoteOption.VOTE_OPTION_TWO_YEARS:
      return "VOTE_OPTION_TWO_YEARS";
    case HistoricalVote_VoteOption.VOTE_OPTION_THREE_YEARS:
      return "VOTE_OPTION_THREE_YEARS";
    case HistoricalVote_VoteOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface HistoricalVote_Organization {
  /** REQUIRED The name of the organization in format organizations/{uuid} */
  name: string;
  /** REQUIRED The title of the organization */
  title: string;
}

export interface HistoricalVote_VotingPolicy {
  /** REQUIRED The name of the voting policy in the format votingPolicies/{uuid} */
  name: string;
  /** REQUIRED The title of the voting policy */
  title: string;
}

export interface HistoricalVote_Outcome {
  /** REQUIRED The decision made on the proposal */
  decision: HistoricalVote_Outcome_Decision;
}

export enum HistoricalVote_Outcome_Decision {
  DECISION_INVALID = 0,
  DECISION_FOR = 1,
  DECISION_AGAINST = 2,
  DECISION_WITHDRAWN = 3,
  /** DECISION_ONE_YEAR - say on frequency options */
  DECISION_ONE_YEAR = 4,
  DECISION_TWO_YEARS = 5,
  DECISION_THREE_YEARS = 6,
  UNRECOGNIZED = -1,
}

export function historicalVote_Outcome_DecisionFromJSON(object: any): HistoricalVote_Outcome_Decision {
  switch (object) {
    case 0:
    case "DECISION_INVALID":
      return HistoricalVote_Outcome_Decision.DECISION_INVALID;
    case 1:
    case "DECISION_FOR":
      return HistoricalVote_Outcome_Decision.DECISION_FOR;
    case 2:
    case "DECISION_AGAINST":
      return HistoricalVote_Outcome_Decision.DECISION_AGAINST;
    case 3:
    case "DECISION_WITHDRAWN":
      return HistoricalVote_Outcome_Decision.DECISION_WITHDRAWN;
    case 4:
    case "DECISION_ONE_YEAR":
      return HistoricalVote_Outcome_Decision.DECISION_ONE_YEAR;
    case 5:
    case "DECISION_TWO_YEARS":
      return HistoricalVote_Outcome_Decision.DECISION_TWO_YEARS;
    case 6:
    case "DECISION_THREE_YEARS":
      return HistoricalVote_Outcome_Decision.DECISION_THREE_YEARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HistoricalVote_Outcome_Decision.UNRECOGNIZED;
  }
}

export function historicalVote_Outcome_DecisionToJSON(object: HistoricalVote_Outcome_Decision): string {
  switch (object) {
    case HistoricalVote_Outcome_Decision.DECISION_INVALID:
      return "DECISION_INVALID";
    case HistoricalVote_Outcome_Decision.DECISION_FOR:
      return "DECISION_FOR";
    case HistoricalVote_Outcome_Decision.DECISION_AGAINST:
      return "DECISION_AGAINST";
    case HistoricalVote_Outcome_Decision.DECISION_WITHDRAWN:
      return "DECISION_WITHDRAWN";
    case HistoricalVote_Outcome_Decision.DECISION_ONE_YEAR:
      return "DECISION_ONE_YEAR";
    case HistoricalVote_Outcome_Decision.DECISION_TWO_YEARS:
      return "DECISION_TWO_YEARS";
    case HistoricalVote_Outcome_Decision.DECISION_THREE_YEARS:
      return "DECISION_THREE_YEARS";
    case HistoricalVote_Outcome_Decision.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseHistoricalVote(): HistoricalVote {
  return {
    name: "",
    title: "",
    organization: undefined,
    meetingDate: undefined,
    themeCodes: [],
    votingPolicy: undefined,
    response: 0,
    outcome: undefined,
  };
}

export const HistoricalVote = {
  encode(message: HistoricalVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.organization !== undefined) {
      HistoricalVote_Organization.encode(message.organization, writer.uint32(26).fork()).ldelim();
    }
    if (message.meetingDate !== undefined) {
      Timestamp.encode(toTimestamp(message.meetingDate), writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.themeCodes) {
      writer.uint32(42).string(v!);
    }
    if (message.votingPolicy !== undefined) {
      HistoricalVote_VotingPolicy.encode(message.votingPolicy, writer.uint32(50).fork()).ldelim();
    }
    if (message.response !== 0) {
      writer.uint32(56).int32(message.response);
    }
    if (message.outcome !== undefined) {
      HistoricalVote_Outcome.encode(message.outcome, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.organization = HistoricalVote_Organization.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.meetingDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.themeCodes.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.votingPolicy = HistoricalVote_VotingPolicy.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.response = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.outcome = HistoricalVote_Outcome.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalVote {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
      organization: isSet(object.organization) ? HistoricalVote_Organization.fromJSON(object.organization) : undefined,
      meetingDate: isSet(object.meetingDate) ? fromJsonTimestamp(object.meetingDate) : undefined,
      themeCodes: globalThis.Array.isArray(object?.themeCodes) ? object.themeCodes.map((e: any) => String(e)) : [],
      votingPolicy: isSet(object.votingPolicy) ? HistoricalVote_VotingPolicy.fromJSON(object.votingPolicy) : undefined,
      response: isSet(object.response) ? historicalVote_VoteOptionFromJSON(object.response) : 0,
      outcome: isSet(object.outcome) ? HistoricalVote_Outcome.fromJSON(object.outcome) : undefined,
    };
  },

  toJSON(message: HistoricalVote): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.organization !== undefined) {
      obj.organization = HistoricalVote_Organization.toJSON(message.organization);
    }
    if (message.meetingDate !== undefined) {
      obj.meetingDate = message.meetingDate.toISOString();
    }
    if (message.themeCodes?.length) {
      obj.themeCodes = message.themeCodes;
    }
    if (message.votingPolicy !== undefined) {
      obj.votingPolicy = HistoricalVote_VotingPolicy.toJSON(message.votingPolicy);
    }
    if (message.response !== 0) {
      obj.response = historicalVote_VoteOptionToJSON(message.response);
    }
    if (message.outcome !== undefined) {
      obj.outcome = HistoricalVote_Outcome.toJSON(message.outcome);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalVote>, I>>(base?: I): HistoricalVote {
    return HistoricalVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalVote>, I>>(object: I): HistoricalVote {
    const message = createBaseHistoricalVote();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    message.organization = (object.organization !== undefined && object.organization !== null)
      ? HistoricalVote_Organization.fromPartial(object.organization)
      : undefined;
    message.meetingDate = object.meetingDate ?? undefined;
    message.themeCodes = object.themeCodes?.map((e) => e) || [];
    message.votingPolicy = (object.votingPolicy !== undefined && object.votingPolicy !== null)
      ? HistoricalVote_VotingPolicy.fromPartial(object.votingPolicy)
      : undefined;
    message.response = object.response ?? 0;
    message.outcome = (object.outcome !== undefined && object.outcome !== null)
      ? HistoricalVote_Outcome.fromPartial(object.outcome)
      : undefined;
    return message;
  },
};

function createBaseHistoricalVote_Organization(): HistoricalVote_Organization {
  return { name: "", title: "" };
}

export const HistoricalVote_Organization = {
  encode(message: HistoricalVote_Organization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalVote_Organization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalVote_Organization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalVote_Organization {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: HistoricalVote_Organization): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalVote_Organization>, I>>(base?: I): HistoricalVote_Organization {
    return HistoricalVote_Organization.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalVote_Organization>, I>>(object: I): HistoricalVote_Organization {
    const message = createBaseHistoricalVote_Organization();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseHistoricalVote_VotingPolicy(): HistoricalVote_VotingPolicy {
  return { name: "", title: "" };
}

export const HistoricalVote_VotingPolicy = {
  encode(message: HistoricalVote_VotingPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalVote_VotingPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalVote_VotingPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalVote_VotingPolicy {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: HistoricalVote_VotingPolicy): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalVote_VotingPolicy>, I>>(base?: I): HistoricalVote_VotingPolicy {
    return HistoricalVote_VotingPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalVote_VotingPolicy>, I>>(object: I): HistoricalVote_VotingPolicy {
    const message = createBaseHistoricalVote_VotingPolicy();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseHistoricalVote_Outcome(): HistoricalVote_Outcome {
  return { decision: 0 };
}

export const HistoricalVote_Outcome = {
  encode(message: HistoricalVote_Outcome, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.decision !== 0) {
      writer.uint32(8).int32(message.decision);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoricalVote_Outcome {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoricalVote_Outcome();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.decision = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoricalVote_Outcome {
    return { decision: isSet(object.decision) ? historicalVote_Outcome_DecisionFromJSON(object.decision) : 0 };
  },

  toJSON(message: HistoricalVote_Outcome): unknown {
    const obj: any = {};
    if (message.decision !== 0) {
      obj.decision = historicalVote_Outcome_DecisionToJSON(message.decision);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoricalVote_Outcome>, I>>(base?: I): HistoricalVote_Outcome {
    return HistoricalVote_Outcome.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoricalVote_Outcome>, I>>(object: I): HistoricalVote_Outcome {
    const message = createBaseHistoricalVote_Outcome();
    message.decision = object.decision ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
