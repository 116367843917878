import useSWR from 'swr'
import { signOut } from 'aws-amplify/auth'
import { useRouter } from 'next/router'
import { User } from '@/application/domain/user'
import { swrConfig } from '@/config/swr/swrConfig'
import { DefaultUser, SampleUser } from '@/application/Mocks/user'
import { useAnalytics } from '@/application/Providers/AnalyticsProvider'
import { getEnvironmentConfig } from '@/application/Environment/getEnvironmentConfig'
import { getUserInfo, getUserInfoKey } from './getUserInfo'

export interface AuthResponse {
  user: User
  error: (() => void) | undefined
  signOut: () => Promise<void>
}

export const useAuth = (): AuthResponse => {
  const { mockAuth } = getEnvironmentConfig()

  if (mockAuth) {
    return { user: SampleUser(), error: undefined, signOut: Promise.resolve }
  }

  const { data, error, isLoading } = useSWR<User, () => void>(getUserInfoKey, getUserInfo, {
    ...swrConfig,
    shouldRetryOnError: false,
  })

  const router = useRouter()
  const analytics = useAnalytics()

  if (!data && isLoading) {
    return { user: DefaultUser(), error: undefined, signOut }
  }

  if (!data) {
    throw new Error('No user data available.')
  }

  // this is a bit of a hack to clear the SWR cache when the user signs out
  // and causes the application to flash on logout
  // currently can't find a better way to do this
  const clearSWRCache = async () => {
    router.reload()
  }

  return {
    user: data,
    error,
    signOut: async () => {
      analytics.signOutUser()
      await signOut()
      await router.push('/')
      await clearSWRCache()
    },
  }
}
