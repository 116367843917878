/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Broker } from "./broker";

export const protobufPackage = "tumelo.retailbff.v1";

export enum BrokerConnectionSyncStatus {
  BROKER_CONNECTION_SYNC_STATUS_INVALID = 0,
  BROKER_CONNECTION_SYNC_STATUS_CONNECTED = 1,
  BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED = 2,
  BROKER_CONNECTION_SYNC_STATUS_LOADING = 3,
  /** BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT - This status indicates when a broker connection is disconnected and reconnecting won't fix the issue. */
  BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT = 4,
  UNRECOGNIZED = -1,
}

export function brokerConnectionSyncStatusFromJSON(object: any): BrokerConnectionSyncStatus {
  switch (object) {
    case 0:
    case "BROKER_CONNECTION_SYNC_STATUS_INVALID":
      return BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_INVALID;
    case 1:
    case "BROKER_CONNECTION_SYNC_STATUS_CONNECTED":
      return BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_CONNECTED;
    case 2:
    case "BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED":
      return BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED;
    case 3:
    case "BROKER_CONNECTION_SYNC_STATUS_LOADING":
      return BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_LOADING;
    case 4:
    case "BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT":
      return BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BrokerConnectionSyncStatus.UNRECOGNIZED;
  }
}

export function brokerConnectionSyncStatusToJSON(object: BrokerConnectionSyncStatus): string {
  switch (object) {
    case BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_INVALID:
      return "BROKER_CONNECTION_SYNC_STATUS_INVALID";
    case BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_CONNECTED:
      return "BROKER_CONNECTION_SYNC_STATUS_CONNECTED";
    case BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED:
      return "BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED";
    case BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_LOADING:
      return "BROKER_CONNECTION_SYNC_STATUS_LOADING";
    case BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT:
      return "BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT";
    case BrokerConnectionSyncStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** a broker connected to an investor */
export interface BrokerConnection {
  /** REQUIRED the broker connection identifier (with format investors/{UUID}/brokerConnections/{UUID}). */
  name: string;
  /** REQUIRED the external id of the broker connection. */
  externalId: string;
  /** REQUIRED the broker of the connection. */
  broker:
    | Broker
    | undefined;
  /** REQUIRED the sync status of the connection */
  brokerConnectionStatus: BrokerConnectionSyncStatus;
}

function createBaseBrokerConnection(): BrokerConnection {
  return { name: "", externalId: "", broker: undefined, brokerConnectionStatus: 0 };
}

export const BrokerConnection = {
  encode(message: BrokerConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.externalId !== "") {
      writer.uint32(18).string(message.externalId);
    }
    if (message.broker !== undefined) {
      Broker.encode(message.broker, writer.uint32(26).fork()).ldelim();
    }
    if (message.brokerConnectionStatus !== 0) {
      writer.uint32(32).int32(message.brokerConnectionStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.broker = Broker.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.brokerConnectionStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrokerConnection {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      externalId: isSet(object.externalId) ? String(object.externalId) : "",
      broker: isSet(object.broker) ? Broker.fromJSON(object.broker) : undefined,
      brokerConnectionStatus: isSet(object.brokerConnectionStatus)
        ? brokerConnectionSyncStatusFromJSON(object.brokerConnectionStatus)
        : 0,
    };
  },

  toJSON(message: BrokerConnection): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.broker !== undefined) {
      obj.broker = Broker.toJSON(message.broker);
    }
    if (message.brokerConnectionStatus !== 0) {
      obj.brokerConnectionStatus = brokerConnectionSyncStatusToJSON(message.brokerConnectionStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerConnection>, I>>(base?: I): BrokerConnection {
    return BrokerConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BrokerConnection>, I>>(object: I): BrokerConnection {
    const message = createBaseBrokerConnection();
    message.name = object.name ?? "";
    message.externalId = object.externalId ?? "";
    message.broker = (object.broker !== undefined && object.broker !== null)
      ? Broker.fromPartial(object.broker)
      : undefined;
    message.brokerConnectionStatus = object.brokerConnectionStatus ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
