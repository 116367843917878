import React from 'react'
import { primitives } from '@aws-amplify/ui-react'
import { AuthLayout } from './AuthLayout'

const { Button } = primitives

interface Props {
  error: string | undefined
  onClickGoBack: () => void
}
export const ConfirmCodeError: React.FC<Props> = ({ error, onClickGoBack }) => {
  const errorString = createErrorString(error)

  return (
    <AuthLayout>
      {errorString}
      <Button variation="link" onClick={() => onClickGoBack()}>
        Go Back
      </Button>
    </AuthLayout>
  )
}

function createErrorString(error: string | undefined): string {
  switch (error) {
    case undefined:
      return 'An error occurred'
    case 'Incorrect username or password.': // map this error message to a more user friendly message
      return 'Incorrect username, password or MFA code.'
    default:
      return error
  }
}
