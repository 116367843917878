import { User } from '@/application/domain/user'

export const SampleUser = (override?: Partial<User>): User => ({
  givenName: 'John',
  familyName: 'Smith',
  email: 'john@smith.com',
  sub: '1234',

  ...override,
})

export const DefaultUser = (override?: Partial<User>): User => ({
  givenName: '',
  familyName: '',
  email: '',
  sub: '',
  ...override,
})
