import { fetchAuthSession } from 'aws-amplify/auth'
import { BrowserHeaders } from 'browser-headers'
import {
  GrpcWebImpl,
  RetailBffService,
  RetailBffServiceClientImpl,
} from '@/application//proto/tumelo/retailbff/v1/service'

export const getGrpcClient = async (clientAddress: string): Promise<RetailBffService> => {
  const session = await fetchAuthSession()
  const token = session.tokens?.idToken?.toString()
  const metadata = new BrowserHeaders({ Authorization: `Bearer ${token}` })
  return new RetailBffServiceClientImpl(new GrpcWebImpl(clientAddress, { metadata }))
}
