/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.retailbff.v1";

export interface VotingPolicy {
  /** name of the policy of the format votingPolicies/{uuid} */
  name: string;
  title: string;
  policyProvider: PolicyProvider | undefined;
  description: string;
  currentDocument: PolicyDocument | undefined;
  updateTime: Date | undefined;
  imageUrl: string;
}

export interface PolicyProvider {
  title: string;
}

export interface PolicyDocument {
  policyDocumentId: string;
  pdfUrl: string;
  issueTime: Date | undefined;
}

function createBaseVotingPolicy(): VotingPolicy {
  return {
    name: "",
    title: "",
    policyProvider: undefined,
    description: "",
    currentDocument: undefined,
    updateTime: undefined,
    imageUrl: "",
  };
}

export const VotingPolicy = {
  encode(message: VotingPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.policyProvider !== undefined) {
      PolicyProvider.encode(message.policyProvider, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.currentDocument !== undefined) {
      PolicyDocument.encode(message.currentDocument, writer.uint32(42).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.imageUrl !== "") {
      writer.uint32(58).string(message.imageUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VotingPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVotingPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.policyProvider = PolicyProvider.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.currentDocument = PolicyDocument.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VotingPolicy {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
      policyProvider: isSet(object.policyProvider) ? PolicyProvider.fromJSON(object.policyProvider) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      currentDocument: isSet(object.currentDocument) ? PolicyDocument.fromJSON(object.currentDocument) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
    };
  },

  toJSON(message: VotingPolicy): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.policyProvider !== undefined) {
      obj.policyProvider = PolicyProvider.toJSON(message.policyProvider);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.currentDocument !== undefined) {
      obj.currentDocument = PolicyDocument.toJSON(message.currentDocument);
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.imageUrl !== "") {
      obj.imageUrl = message.imageUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VotingPolicy>, I>>(base?: I): VotingPolicy {
    return VotingPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VotingPolicy>, I>>(object: I): VotingPolicy {
    const message = createBaseVotingPolicy();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    message.policyProvider = (object.policyProvider !== undefined && object.policyProvider !== null)
      ? PolicyProvider.fromPartial(object.policyProvider)
      : undefined;
    message.description = object.description ?? "";
    message.currentDocument = (object.currentDocument !== undefined && object.currentDocument !== null)
      ? PolicyDocument.fromPartial(object.currentDocument)
      : undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.imageUrl = object.imageUrl ?? "";
    return message;
  },
};

function createBasePolicyProvider(): PolicyProvider {
  return { title: "" };
}

export const PolicyProvider = {
  encode(message: PolicyProvider, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyProvider {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyProvider();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyProvider {
    return { title: isSet(object.title) ? String(object.title) : "" };
  },

  toJSON(message: PolicyProvider): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyProvider>, I>>(base?: I): PolicyProvider {
    return PolicyProvider.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PolicyProvider>, I>>(object: I): PolicyProvider {
    const message = createBasePolicyProvider();
    message.title = object.title ?? "";
    return message;
  },
};

function createBasePolicyDocument(): PolicyDocument {
  return { policyDocumentId: "", pdfUrl: "", issueTime: undefined };
}

export const PolicyDocument = {
  encode(message: PolicyDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.policyDocumentId !== "") {
      writer.uint32(10).string(message.policyDocumentId);
    }
    if (message.pdfUrl !== "") {
      writer.uint32(18).string(message.pdfUrl);
    }
    if (message.issueTime !== undefined) {
      Timestamp.encode(toTimestamp(message.issueTime), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyDocument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.policyDocumentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdfUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.issueTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyDocument {
    return {
      policyDocumentId: isSet(object.policyDocumentId) ? String(object.policyDocumentId) : "",
      pdfUrl: isSet(object.pdfUrl) ? String(object.pdfUrl) : "",
      issueTime: isSet(object.issueTime) ? fromJsonTimestamp(object.issueTime) : undefined,
    };
  },

  toJSON(message: PolicyDocument): unknown {
    const obj: any = {};
    if (message.policyDocumentId !== "") {
      obj.policyDocumentId = message.policyDocumentId;
    }
    if (message.pdfUrl !== "") {
      obj.pdfUrl = message.pdfUrl;
    }
    if (message.issueTime !== undefined) {
      obj.issueTime = message.issueTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyDocument>, I>>(base?: I): PolicyDocument {
    return PolicyDocument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PolicyDocument>, I>>(object: I): PolicyDocument {
    const message = createBasePolicyDocument();
    message.policyDocumentId = object.policyDocumentId ?? "";
    message.pdfUrl = object.pdfUrl ?? "";
    message.issueTime = object.issueTime ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
