/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.retailaccountconnector.v1";

/**
 * Broker is a struct that represents a broker-connector -specific broker.
 * Eg. HL via moneyhub.
 */
export interface Broker {
  /** GENERATED name is in the format `brokerConnectors/moneyhub/broker/{uuid}` */
  name: string;
  /** REQUIRED title of broker to display */
  title: string;
  /** OPTIONAL url of broker logo */
  logoUrl: string;
  /** REQUIRED id used by the third party broker connnector to identify the broker */
  externalId: string;
  /** REQUIRED is the broker active (available for a user to select) */
  isActive: boolean;
  createTime: Date | undefined;
  updateTime: Date | undefined;
}

function createBaseBroker(): Broker {
  return {
    name: "",
    title: "",
    logoUrl: "",
    externalId: "",
    isActive: false,
    createTime: undefined,
    updateTime: undefined,
  };
}

export const Broker = {
  encode(message: Broker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.logoUrl !== "") {
      writer.uint32(26).string(message.logoUrl);
    }
    if (message.externalId !== "") {
      writer.uint32(34).string(message.externalId);
    }
    if (message.isActive === true) {
      writer.uint32(40).bool(message.isActive);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Broker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBroker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Broker {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      externalId: isSet(object.externalId) ? String(object.externalId) : "",
      isActive: isSet(object.isActive) ? Boolean(object.isActive) : false,
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
    };
  },

  toJSON(message: Broker): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.isActive === true) {
      obj.isActive = message.isActive;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Broker>, I>>(base?: I): Broker {
    return Broker.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Broker>, I>>(object: I): Broker {
    const message = createBaseBroker();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.externalId = object.externalId ?? "";
    message.isActive = object.isActive ?? false;
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
