/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "../../../google/protobuf/empty";
import {
  BrokerConnectionFlow,
  brokerConnectionFlowFromJSON,
  brokerConnectionFlowToJSON,
} from "../../retailaccountconnector/v1/service";
import { AccountsSummary } from "./account";
import { Broker } from "./broker";
import { BrokerConnection } from "./broker_connection";
import { HistoricalVote } from "./historical_votes";
import { VotingPolicy } from "./voting_policy";

export const protobufPackage = "tumelo.retailbff.v1";

export interface ListBrokersRequest {
  /** OPTIONAL page size */
  pageSize: number;
  /** OPTIONAL pagination token */
  pageToken: string;
}

export interface ListBrokersResponse {
  nextPageToken: string;
  /** list of brokers meeting the matched request */
  brokers: Broker[];
}

export interface StartBrokerConnectionRequest {
  /** REQUIRED the broker identifier (with format brokerConnectors/moneyhub/brokers/{UUID}) */
  brokerName: string;
}

export interface StartBrokerConnectionResponse {
  /** REQUIRED url matching the request for the investor to start a broker connection */
  url: string;
}

export interface CompleteBrokerConnectionRequest {
  /** REQUIRED the code associated to the broker to authorize the request */
  authorizationCode: string;
  /** broker_connector_name is in the format `brokerConnectors/moneyhub` */
  brokerConnectorName: string;
  /** broker_connection_flow is the type of flow for the broker connection */
  brokerConnectionFlow: BrokerConnectionFlow;
}

export interface CompleteBrokerConnectionResponse {
  /** broker_connection_name name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface ListBrokerConnectionsRequest {
  /** OPTIONAL page size */
  pageSize: number;
  /** OPTIONAL pagination token */
  pageToken: string;
}

export interface DeleteBrokerConnectionRequest {
  /** broker_connection_name name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface ListBrokerConnectionsResponse {
  nextPageToken: string;
  /** list of brokers meeting the matched request */
  brokers: BrokerConnection[];
}

export interface GetSelectedVotingPolicyRequest {
}

export interface GetSelectedVotingPolicyResponse {
  votingPolicy: VotingPolicy | undefined;
}

export interface FetchBrokerConnectionSummaryRequest {
  /** broker_connection_name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface FetchAvailableVotingPoliciesRequest {
}

export interface FetchAvailableVotingPoliciesResponse {
  votingPolicies: VotingPolicy[];
}

export interface UpsertVotingPolicySelectionRequest {
  /** REQUIRED the voting policy name  of the format votingPolicies/{uuid} to upsert the selection for */
  votingPolicyName: string;
}

export interface UpsertVotingPolicySelectionResponse {
}

export interface ListHistoricalVotesRequest {
  /** OPTIONAL pagination token */
  pageToken: string;
}

export interface ListHistoricalVotesResponse {
  /** REQUIRED pagination token */
  nextPageToken: string;
  /** REQUIRED list of proposal vote reports */
  historicalVotes: HistoricalVote[];
}

export interface RefreshBrokerConnectionRequest {
  /** broker_connection_name is in the format `habitats/{uuid}/investors/{uuid}/brokerConnections/{uuid}` */
  brokerConnectionName: string;
}

export interface RefreshBrokerConnectionResponse {
  refreshUrl: string;
}

export interface GetUserRequest {
}

export interface GetUserResponse {
  userId: string;
  investorName: string;
}

function createBaseListBrokersRequest(): ListBrokersRequest {
  return { pageSize: 0, pageToken: "" };
}

export const ListBrokersRequest = {
  encode(message: ListBrokersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokersRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
    };
  },

  toJSON(message: ListBrokersRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokersRequest>, I>>(base?: I): ListBrokersRequest {
    return ListBrokersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokersRequest>, I>>(object: I): ListBrokersRequest {
    const message = createBaseListBrokersRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListBrokersResponse(): ListBrokersResponse {
  return { nextPageToken: "", brokers: [] };
}

export const ListBrokersResponse = {
  encode(message: ListBrokersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.brokers) {
      Broker.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokers.push(Broker.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokersResponse {
    return {
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
      brokers: globalThis.Array.isArray(object?.brokers) ? object.brokers.map((e: any) => Broker.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListBrokersResponse): unknown {
    const obj: any = {};
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    if (message.brokers?.length) {
      obj.brokers = message.brokers.map((e) => Broker.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokersResponse>, I>>(base?: I): ListBrokersResponse {
    return ListBrokersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokersResponse>, I>>(object: I): ListBrokersResponse {
    const message = createBaseListBrokersResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.brokers = object.brokers?.map((e) => Broker.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStartBrokerConnectionRequest(): StartBrokerConnectionRequest {
  return { brokerName: "" };
}

export const StartBrokerConnectionRequest = {
  encode(message: StartBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerName !== "") {
      writer.uint32(10).string(message.brokerName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartBrokerConnectionRequest {
    return { brokerName: isSet(object.brokerName) ? String(object.brokerName) : "" };
  },

  toJSON(message: StartBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerName !== "") {
      obj.brokerName = message.brokerName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartBrokerConnectionRequest>, I>>(base?: I): StartBrokerConnectionRequest {
    return StartBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartBrokerConnectionRequest>, I>>(object: I): StartBrokerConnectionRequest {
    const message = createBaseStartBrokerConnectionRequest();
    message.brokerName = object.brokerName ?? "";
    return message;
  },
};

function createBaseStartBrokerConnectionResponse(): StartBrokerConnectionResponse {
  return { url: "" };
}

export const StartBrokerConnectionResponse = {
  encode(message: StartBrokerConnectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartBrokerConnectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartBrokerConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StartBrokerConnectionResponse {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: StartBrokerConnectionResponse): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StartBrokerConnectionResponse>, I>>(base?: I): StartBrokerConnectionResponse {
    return StartBrokerConnectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StartBrokerConnectionResponse>, I>>(
    object: I,
  ): StartBrokerConnectionResponse {
    const message = createBaseStartBrokerConnectionResponse();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseCompleteBrokerConnectionRequest(): CompleteBrokerConnectionRequest {
  return { authorizationCode: "", brokerConnectorName: "", brokerConnectionFlow: 0 };
}

export const CompleteBrokerConnectionRequest = {
  encode(message: CompleteBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authorizationCode !== "") {
      writer.uint32(10).string(message.authorizationCode);
    }
    if (message.brokerConnectorName !== "") {
      writer.uint32(18).string(message.brokerConnectorName);
    }
    if (message.brokerConnectionFlow !== 0) {
      writer.uint32(24).int32(message.brokerConnectionFlow);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompleteBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authorizationCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokerConnectorName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.brokerConnectionFlow = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompleteBrokerConnectionRequest {
    return {
      authorizationCode: isSet(object.authorizationCode) ? String(object.authorizationCode) : "",
      brokerConnectorName: isSet(object.brokerConnectorName) ? String(object.brokerConnectorName) : "",
      brokerConnectionFlow: isSet(object.brokerConnectionFlow)
        ? brokerConnectionFlowFromJSON(object.brokerConnectionFlow)
        : 0,
    };
  },

  toJSON(message: CompleteBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.authorizationCode !== "") {
      obj.authorizationCode = message.authorizationCode;
    }
    if (message.brokerConnectorName !== "") {
      obj.brokerConnectorName = message.brokerConnectorName;
    }
    if (message.brokerConnectionFlow !== 0) {
      obj.brokerConnectionFlow = brokerConnectionFlowToJSON(message.brokerConnectionFlow);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompleteBrokerConnectionRequest>, I>>(base?: I): CompleteBrokerConnectionRequest {
    return CompleteBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompleteBrokerConnectionRequest>, I>>(
    object: I,
  ): CompleteBrokerConnectionRequest {
    const message = createBaseCompleteBrokerConnectionRequest();
    message.authorizationCode = object.authorizationCode ?? "";
    message.brokerConnectorName = object.brokerConnectorName ?? "";
    message.brokerConnectionFlow = object.brokerConnectionFlow ?? 0;
    return message;
  },
};

function createBaseCompleteBrokerConnectionResponse(): CompleteBrokerConnectionResponse {
  return { brokerConnectionName: "" };
}

export const CompleteBrokerConnectionResponse = {
  encode(message: CompleteBrokerConnectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompleteBrokerConnectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteBrokerConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompleteBrokerConnectionResponse {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: CompleteBrokerConnectionResponse): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompleteBrokerConnectionResponse>, I>>(
    base?: I,
  ): CompleteBrokerConnectionResponse {
    return CompleteBrokerConnectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompleteBrokerConnectionResponse>, I>>(
    object: I,
  ): CompleteBrokerConnectionResponse {
    const message = createBaseCompleteBrokerConnectionResponse();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseListBrokerConnectionsRequest(): ListBrokerConnectionsRequest {
  return { pageSize: 0, pageToken: "" };
}

export const ListBrokerConnectionsRequest = {
  encode(message: ListBrokerConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokerConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokerConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokerConnectionsRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
    };
  },

  toJSON(message: ListBrokerConnectionsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokerConnectionsRequest>, I>>(base?: I): ListBrokerConnectionsRequest {
    return ListBrokerConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokerConnectionsRequest>, I>>(object: I): ListBrokerConnectionsRequest {
    const message = createBaseListBrokerConnectionsRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseDeleteBrokerConnectionRequest(): DeleteBrokerConnectionRequest {
  return { brokerConnectionName: "" };
}

export const DeleteBrokerConnectionRequest = {
  encode(message: DeleteBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteBrokerConnectionRequest {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: DeleteBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteBrokerConnectionRequest>, I>>(base?: I): DeleteBrokerConnectionRequest {
    return DeleteBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteBrokerConnectionRequest>, I>>(
    object: I,
  ): DeleteBrokerConnectionRequest {
    const message = createBaseDeleteBrokerConnectionRequest();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseListBrokerConnectionsResponse(): ListBrokerConnectionsResponse {
  return { nextPageToken: "", brokers: [] };
}

export const ListBrokerConnectionsResponse = {
  encode(message: ListBrokerConnectionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.brokers) {
      BrokerConnection.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBrokerConnectionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBrokerConnectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokers.push(BrokerConnection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBrokerConnectionsResponse {
    return {
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
      brokers: globalThis.Array.isArray(object?.brokers)
        ? object.brokers.map((e: any) => BrokerConnection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListBrokerConnectionsResponse): unknown {
    const obj: any = {};
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    if (message.brokers?.length) {
      obj.brokers = message.brokers.map((e) => BrokerConnection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBrokerConnectionsResponse>, I>>(base?: I): ListBrokerConnectionsResponse {
    return ListBrokerConnectionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBrokerConnectionsResponse>, I>>(
    object: I,
  ): ListBrokerConnectionsResponse {
    const message = createBaseListBrokerConnectionsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.brokers = object.brokers?.map((e) => BrokerConnection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetSelectedVotingPolicyRequest(): GetSelectedVotingPolicyRequest {
  return {};
}

export const GetSelectedVotingPolicyRequest = {
  encode(_: GetSelectedVotingPolicyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSelectedVotingPolicyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSelectedVotingPolicyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetSelectedVotingPolicyRequest {
    return {};
  },

  toJSON(_: GetSelectedVotingPolicyRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSelectedVotingPolicyRequest>, I>>(base?: I): GetSelectedVotingPolicyRequest {
    return GetSelectedVotingPolicyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSelectedVotingPolicyRequest>, I>>(_: I): GetSelectedVotingPolicyRequest {
    const message = createBaseGetSelectedVotingPolicyRequest();
    return message;
  },
};

function createBaseGetSelectedVotingPolicyResponse(): GetSelectedVotingPolicyResponse {
  return { votingPolicy: undefined };
}

export const GetSelectedVotingPolicyResponse = {
  encode(message: GetSelectedVotingPolicyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.votingPolicy !== undefined) {
      VotingPolicy.encode(message.votingPolicy, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSelectedVotingPolicyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSelectedVotingPolicyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votingPolicy = VotingPolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSelectedVotingPolicyResponse {
    return { votingPolicy: isSet(object.votingPolicy) ? VotingPolicy.fromJSON(object.votingPolicy) : undefined };
  },

  toJSON(message: GetSelectedVotingPolicyResponse): unknown {
    const obj: any = {};
    if (message.votingPolicy !== undefined) {
      obj.votingPolicy = VotingPolicy.toJSON(message.votingPolicy);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSelectedVotingPolicyResponse>, I>>(base?: I): GetSelectedVotingPolicyResponse {
    return GetSelectedVotingPolicyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSelectedVotingPolicyResponse>, I>>(
    object: I,
  ): GetSelectedVotingPolicyResponse {
    const message = createBaseGetSelectedVotingPolicyResponse();
    message.votingPolicy = (object.votingPolicy !== undefined && object.votingPolicy !== null)
      ? VotingPolicy.fromPartial(object.votingPolicy)
      : undefined;
    return message;
  },
};

function createBaseFetchBrokerConnectionSummaryRequest(): FetchBrokerConnectionSummaryRequest {
  return { brokerConnectionName: "" };
}

export const FetchBrokerConnectionSummaryRequest = {
  encode(message: FetchBrokerConnectionSummaryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchBrokerConnectionSummaryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchBrokerConnectionSummaryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchBrokerConnectionSummaryRequest {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: FetchBrokerConnectionSummaryRequest): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchBrokerConnectionSummaryRequest>, I>>(
    base?: I,
  ): FetchBrokerConnectionSummaryRequest {
    return FetchBrokerConnectionSummaryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchBrokerConnectionSummaryRequest>, I>>(
    object: I,
  ): FetchBrokerConnectionSummaryRequest {
    const message = createBaseFetchBrokerConnectionSummaryRequest();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseFetchAvailableVotingPoliciesRequest(): FetchAvailableVotingPoliciesRequest {
  return {};
}

export const FetchAvailableVotingPoliciesRequest = {
  encode(_: FetchAvailableVotingPoliciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAvailableVotingPoliciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAvailableVotingPoliciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchAvailableVotingPoliciesRequest {
    return {};
  },

  toJSON(_: FetchAvailableVotingPoliciesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAvailableVotingPoliciesRequest>, I>>(
    base?: I,
  ): FetchAvailableVotingPoliciesRequest {
    return FetchAvailableVotingPoliciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAvailableVotingPoliciesRequest>, I>>(
    _: I,
  ): FetchAvailableVotingPoliciesRequest {
    const message = createBaseFetchAvailableVotingPoliciesRequest();
    return message;
  },
};

function createBaseFetchAvailableVotingPoliciesResponse(): FetchAvailableVotingPoliciesResponse {
  return { votingPolicies: [] };
}

export const FetchAvailableVotingPoliciesResponse = {
  encode(message: FetchAvailableVotingPoliciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.votingPolicies) {
      VotingPolicy.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchAvailableVotingPoliciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchAvailableVotingPoliciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votingPolicies.push(VotingPolicy.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchAvailableVotingPoliciesResponse {
    return {
      votingPolicies: globalThis.Array.isArray(object?.votingPolicies)
        ? object.votingPolicies.map((e: any) => VotingPolicy.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FetchAvailableVotingPoliciesResponse): unknown {
    const obj: any = {};
    if (message.votingPolicies?.length) {
      obj.votingPolicies = message.votingPolicies.map((e) => VotingPolicy.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchAvailableVotingPoliciesResponse>, I>>(
    base?: I,
  ): FetchAvailableVotingPoliciesResponse {
    return FetchAvailableVotingPoliciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchAvailableVotingPoliciesResponse>, I>>(
    object: I,
  ): FetchAvailableVotingPoliciesResponse {
    const message = createBaseFetchAvailableVotingPoliciesResponse();
    message.votingPolicies = object.votingPolicies?.map((e) => VotingPolicy.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpsertVotingPolicySelectionRequest(): UpsertVotingPolicySelectionRequest {
  return { votingPolicyName: "" };
}

export const UpsertVotingPolicySelectionRequest = {
  encode(message: UpsertVotingPolicySelectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.votingPolicyName !== "") {
      writer.uint32(10).string(message.votingPolicyName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpsertVotingPolicySelectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpsertVotingPolicySelectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votingPolicyName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpsertVotingPolicySelectionRequest {
    return { votingPolicyName: isSet(object.votingPolicyName) ? String(object.votingPolicyName) : "" };
  },

  toJSON(message: UpsertVotingPolicySelectionRequest): unknown {
    const obj: any = {};
    if (message.votingPolicyName !== "") {
      obj.votingPolicyName = message.votingPolicyName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpsertVotingPolicySelectionRequest>, I>>(
    base?: I,
  ): UpsertVotingPolicySelectionRequest {
    return UpsertVotingPolicySelectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpsertVotingPolicySelectionRequest>, I>>(
    object: I,
  ): UpsertVotingPolicySelectionRequest {
    const message = createBaseUpsertVotingPolicySelectionRequest();
    message.votingPolicyName = object.votingPolicyName ?? "";
    return message;
  },
};

function createBaseUpsertVotingPolicySelectionResponse(): UpsertVotingPolicySelectionResponse {
  return {};
}

export const UpsertVotingPolicySelectionResponse = {
  encode(_: UpsertVotingPolicySelectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpsertVotingPolicySelectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpsertVotingPolicySelectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpsertVotingPolicySelectionResponse {
    return {};
  },

  toJSON(_: UpsertVotingPolicySelectionResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpsertVotingPolicySelectionResponse>, I>>(
    base?: I,
  ): UpsertVotingPolicySelectionResponse {
    return UpsertVotingPolicySelectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpsertVotingPolicySelectionResponse>, I>>(
    _: I,
  ): UpsertVotingPolicySelectionResponse {
    const message = createBaseUpsertVotingPolicySelectionResponse();
    return message;
  },
};

function createBaseListHistoricalVotesRequest(): ListHistoricalVotesRequest {
  return { pageToken: "" };
}

export const ListHistoricalVotesRequest = {
  encode(message: ListHistoricalVotesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListHistoricalVotesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListHistoricalVotesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListHistoricalVotesRequest {
    return { pageToken: isSet(object.pageToken) ? String(object.pageToken) : "" };
  },

  toJSON(message: ListHistoricalVotesRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListHistoricalVotesRequest>, I>>(base?: I): ListHistoricalVotesRequest {
    return ListHistoricalVotesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListHistoricalVotesRequest>, I>>(object: I): ListHistoricalVotesRequest {
    const message = createBaseListHistoricalVotesRequest();
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListHistoricalVotesResponse(): ListHistoricalVotesResponse {
  return { nextPageToken: "", historicalVotes: [] };
}

export const ListHistoricalVotesResponse = {
  encode(message: ListHistoricalVotesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.historicalVotes) {
      HistoricalVote.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListHistoricalVotesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListHistoricalVotesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.historicalVotes.push(HistoricalVote.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListHistoricalVotesResponse {
    return {
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
      historicalVotes: globalThis.Array.isArray(object?.historicalVotes)
        ? object.historicalVotes.map((e: any) => HistoricalVote.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListHistoricalVotesResponse): unknown {
    const obj: any = {};
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    if (message.historicalVotes?.length) {
      obj.historicalVotes = message.historicalVotes.map((e) => HistoricalVote.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListHistoricalVotesResponse>, I>>(base?: I): ListHistoricalVotesResponse {
    return ListHistoricalVotesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListHistoricalVotesResponse>, I>>(object: I): ListHistoricalVotesResponse {
    const message = createBaseListHistoricalVotesResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.historicalVotes = object.historicalVotes?.map((e) => HistoricalVote.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRefreshBrokerConnectionRequest(): RefreshBrokerConnectionRequest {
  return { brokerConnectionName: "" };
}

export const RefreshBrokerConnectionRequest = {
  encode(message: RefreshBrokerConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerConnectionName !== "") {
      writer.uint32(10).string(message.brokerConnectionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshBrokerConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshBrokerConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerConnectionName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshBrokerConnectionRequest {
    return { brokerConnectionName: isSet(object.brokerConnectionName) ? String(object.brokerConnectionName) : "" };
  },

  toJSON(message: RefreshBrokerConnectionRequest): unknown {
    const obj: any = {};
    if (message.brokerConnectionName !== "") {
      obj.brokerConnectionName = message.brokerConnectionName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshBrokerConnectionRequest>, I>>(base?: I): RefreshBrokerConnectionRequest {
    return RefreshBrokerConnectionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshBrokerConnectionRequest>, I>>(
    object: I,
  ): RefreshBrokerConnectionRequest {
    const message = createBaseRefreshBrokerConnectionRequest();
    message.brokerConnectionName = object.brokerConnectionName ?? "";
    return message;
  },
};

function createBaseRefreshBrokerConnectionResponse(): RefreshBrokerConnectionResponse {
  return { refreshUrl: "" };
}

export const RefreshBrokerConnectionResponse = {
  encode(message: RefreshBrokerConnectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.refreshUrl !== "") {
      writer.uint32(10).string(message.refreshUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshBrokerConnectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshBrokerConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refreshUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshBrokerConnectionResponse {
    return { refreshUrl: isSet(object.refreshUrl) ? String(object.refreshUrl) : "" };
  },

  toJSON(message: RefreshBrokerConnectionResponse): unknown {
    const obj: any = {};
    if (message.refreshUrl !== "") {
      obj.refreshUrl = message.refreshUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshBrokerConnectionResponse>, I>>(base?: I): RefreshBrokerConnectionResponse {
    return RefreshBrokerConnectionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshBrokerConnectionResponse>, I>>(
    object: I,
  ): RefreshBrokerConnectionResponse {
    const message = createBaseRefreshBrokerConnectionResponse();
    message.refreshUrl = object.refreshUrl ?? "";
    return message;
  },
};

function createBaseGetUserRequest(): GetUserRequest {
  return {};
}

export const GetUserRequest = {
  encode(_: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserRequest {
    return {};
  },

  toJSON(_: GetUserRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(_: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    return message;
  },
};

function createBaseGetUserResponse(): GetUserResponse {
  return { userId: "", investorName: "" };
}

export const GetUserResponse = {
  encode(message: GetUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.investorName !== "") {
      writer.uint32(18).string(message.investorName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.investorName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserResponse {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      investorName: isSet(object.investorName) ? String(object.investorName) : "",
    };
  },

  toJSON(message: GetUserResponse): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.investorName !== "") {
      obj.investorName = message.investorName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserResponse>, I>>(base?: I): GetUserResponse {
    return GetUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserResponse>, I>>(object: I): GetUserResponse {
    const message = createBaseGetUserResponse();
    message.userId = object.userId ?? "";
    message.investorName = object.investorName ?? "";
    return message;
  },
};

/** RetailBffService is a backend service for the retail platform */
export interface RetailBffService {
  /** ListBrokers returns a paginated list of brokers matching the request */
  ListBrokers(request: DeepPartial<ListBrokersRequest>, metadata?: grpc.Metadata): Promise<ListBrokersResponse>;
  /** StartBrokerConnection returns a url matching the request for the investor to start a broker connection */
  StartBrokerConnection(
    request: DeepPartial<StartBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartBrokerConnectionResponse>;
  /** CompleteBrokerConnection returns the accounts associated to a completed broker connection */
  CompleteBrokerConnection(
    request: DeepPartial<CompleteBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CompleteBrokerConnectionResponse>;
  /** ListConnectedBrokers returns a list of broker connections matching the request */
  ListBrokerConnections(
    request: DeepPartial<ListBrokerConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListBrokerConnectionsResponse>;
  /** DeleteBrokerConnection deletes the broker connection matching the request */
  DeleteBrokerConnection(request: DeepPartial<DeleteBrokerConnectionRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * GetSelectedVotingPolicy returns the investors selected voting policy
   *
   * If no voting policy is selected, an empty response rather than a NotFound error is returned
   */
  GetSelectedVotingPolicy(
    request: DeepPartial<GetSelectedVotingPolicyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSelectedVotingPolicyResponse>;
  /** FetchBrokerConnectionSummary returns a summary of a broker connection, listing all the accounts & investments within those accounts */
  FetchBrokerConnectionSummary(
    request: DeepPartial<FetchBrokerConnectionSummaryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AccountsSummary>;
  /** FetchAvailableVotingPolicies returns all available voting policies for an investor */
  FetchAvailableVotingPolicies(
    request: DeepPartial<FetchAvailableVotingPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchAvailableVotingPoliciesResponse>;
  /** UpsertVotingPolicySelection upserts a voting policy selection for an investor */
  UpsertVotingPolicySelection(
    request: DeepPartial<UpsertVotingPolicySelectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpsertVotingPolicySelectionResponse>;
  /**
   * ListHistoricalVotes returns a paginated list of proposals with outcomes.
   *
   * Each page contains a list of proposals for a 1 month period.
   */
  ListHistoricalVotes(
    request: DeepPartial<ListHistoricalVotesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListHistoricalVotesResponse>;
  /** RefreshBrokerConnection returns a refresh/reauth url for the investor */
  RefreshBrokerConnection(
    request: DeepPartial<RefreshBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshBrokerConnectionResponse>;
  /** GetUser returns the logged-in user's user_id and investor_id */
  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse>;
}

export class RetailBffServiceClientImpl implements RetailBffService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListBrokers = this.ListBrokers.bind(this);
    this.StartBrokerConnection = this.StartBrokerConnection.bind(this);
    this.CompleteBrokerConnection = this.CompleteBrokerConnection.bind(this);
    this.ListBrokerConnections = this.ListBrokerConnections.bind(this);
    this.DeleteBrokerConnection = this.DeleteBrokerConnection.bind(this);
    this.GetSelectedVotingPolicy = this.GetSelectedVotingPolicy.bind(this);
    this.FetchBrokerConnectionSummary = this.FetchBrokerConnectionSummary.bind(this);
    this.FetchAvailableVotingPolicies = this.FetchAvailableVotingPolicies.bind(this);
    this.UpsertVotingPolicySelection = this.UpsertVotingPolicySelection.bind(this);
    this.ListHistoricalVotes = this.ListHistoricalVotes.bind(this);
    this.RefreshBrokerConnection = this.RefreshBrokerConnection.bind(this);
    this.GetUser = this.GetUser.bind(this);
  }

  ListBrokers(request: DeepPartial<ListBrokersRequest>, metadata?: grpc.Metadata): Promise<ListBrokersResponse> {
    return this.rpc.unary(RetailBffServiceListBrokersDesc, ListBrokersRequest.fromPartial(request), metadata);
  }

  StartBrokerConnection(
    request: DeepPartial<StartBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StartBrokerConnectionResponse> {
    return this.rpc.unary(
      RetailBffServiceStartBrokerConnectionDesc,
      StartBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  CompleteBrokerConnection(
    request: DeepPartial<CompleteBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CompleteBrokerConnectionResponse> {
    return this.rpc.unary(
      RetailBffServiceCompleteBrokerConnectionDesc,
      CompleteBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  ListBrokerConnections(
    request: DeepPartial<ListBrokerConnectionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListBrokerConnectionsResponse> {
    return this.rpc.unary(
      RetailBffServiceListBrokerConnectionsDesc,
      ListBrokerConnectionsRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteBrokerConnection(
    request: DeepPartial<DeleteBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      RetailBffServiceDeleteBrokerConnectionDesc,
      DeleteBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  GetSelectedVotingPolicy(
    request: DeepPartial<GetSelectedVotingPolicyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSelectedVotingPolicyResponse> {
    return this.rpc.unary(
      RetailBffServiceGetSelectedVotingPolicyDesc,
      GetSelectedVotingPolicyRequest.fromPartial(request),
      metadata,
    );
  }

  FetchBrokerConnectionSummary(
    request: DeepPartial<FetchBrokerConnectionSummaryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AccountsSummary> {
    return this.rpc.unary(
      RetailBffServiceFetchBrokerConnectionSummaryDesc,
      FetchBrokerConnectionSummaryRequest.fromPartial(request),
      metadata,
    );
  }

  FetchAvailableVotingPolicies(
    request: DeepPartial<FetchAvailableVotingPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchAvailableVotingPoliciesResponse> {
    return this.rpc.unary(
      RetailBffServiceFetchAvailableVotingPoliciesDesc,
      FetchAvailableVotingPoliciesRequest.fromPartial(request),
      metadata,
    );
  }

  UpsertVotingPolicySelection(
    request: DeepPartial<UpsertVotingPolicySelectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpsertVotingPolicySelectionResponse> {
    return this.rpc.unary(
      RetailBffServiceUpsertVotingPolicySelectionDesc,
      UpsertVotingPolicySelectionRequest.fromPartial(request),
      metadata,
    );
  }

  ListHistoricalVotes(
    request: DeepPartial<ListHistoricalVotesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListHistoricalVotesResponse> {
    return this.rpc.unary(
      RetailBffServiceListHistoricalVotesDesc,
      ListHistoricalVotesRequest.fromPartial(request),
      metadata,
    );
  }

  RefreshBrokerConnection(
    request: DeepPartial<RefreshBrokerConnectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshBrokerConnectionResponse> {
    return this.rpc.unary(
      RetailBffServiceRefreshBrokerConnectionDesc,
      RefreshBrokerConnectionRequest.fromPartial(request),
      metadata,
    );
  }

  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse> {
    return this.rpc.unary(RetailBffServiceGetUserDesc, GetUserRequest.fromPartial(request), metadata);
  }
}

export const RetailBffServiceDesc = { serviceName: "tumelo.retailbff.v1.RetailBffService" };

export const RetailBffServiceListBrokersDesc: UnaryMethodDefinitionish = {
  methodName: "ListBrokers",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListBrokersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListBrokersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceStartBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "StartBrokerConnection",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StartBrokerConnectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceCompleteBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "CompleteBrokerConnection",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CompleteBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CompleteBrokerConnectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceListBrokerConnectionsDesc: UnaryMethodDefinitionish = {
  methodName: "ListBrokerConnections",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListBrokerConnectionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListBrokerConnectionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceDeleteBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteBrokerConnection",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceGetSelectedVotingPolicyDesc: UnaryMethodDefinitionish = {
  methodName: "GetSelectedVotingPolicy",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSelectedVotingPolicyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSelectedVotingPolicyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceFetchBrokerConnectionSummaryDesc: UnaryMethodDefinitionish = {
  methodName: "FetchBrokerConnectionSummary",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchBrokerConnectionSummaryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AccountsSummary.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceFetchAvailableVotingPoliciesDesc: UnaryMethodDefinitionish = {
  methodName: "FetchAvailableVotingPolicies",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchAvailableVotingPoliciesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchAvailableVotingPoliciesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceUpsertVotingPolicySelectionDesc: UnaryMethodDefinitionish = {
  methodName: "UpsertVotingPolicySelection",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpsertVotingPolicySelectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpsertVotingPolicySelectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceListHistoricalVotesDesc: UnaryMethodDefinitionish = {
  methodName: "ListHistoricalVotes",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListHistoricalVotesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListHistoricalVotesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceRefreshBrokerConnectionDesc: UnaryMethodDefinitionish = {
  methodName: "RefreshBrokerConnection",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RefreshBrokerConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefreshBrokerConnectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RetailBffServiceGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: RetailBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
