import React, { useContext, useEffect, createContext } from 'react'
import { useRouter } from 'next/router'
import { Analytics, createAnalyticsMixpanel, AnalyticsConsoleLogger, combineAnalytics } from '@tumelo/analytics'
import { getEnvironmentConfig } from '@/application/Environment/getEnvironmentConfig'
import { useLocalStorage } from '@/utilities/useLocalStorage'
import { CookieConsentValue, cookieConsentKey } from '@/components/CookieBanner'
import { UserContext } from '@/application/Providers/AuthProvider'

export const AnalyticsContext = createContext<Analytics>(AnalyticsConsoleLogger)

export const useAnalytics = () => useContext(AnalyticsContext)

export const SetUserProperties: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { user } = useContext(UserContext)
  const analytics = useAnalytics()
  useEffect(() => {
    if (user) {
      analytics.setUserProperties(user.sub)
    }
  }, [analytics, user])
  return <>{children}</>
}

const useExplicitPageView = (analytics: Analytics, page: string) =>
  useEffect(() => {
    analytics.pageView(page)
  }, [analytics, page])

export const PageViewFromLocationPathname: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter()
  const analytics = useAnalytics()
  useExplicitPageView(analytics, router.pathname)
  return <>{children}</>
}

export const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const analyticsEngines: Analytics[] = []
  const { mixpanelToken, environment } = getEnvironmentConfig()

  const mixpanelConfig =
    environment === 'prod'
      ? { api_host: 'https://api-eu.mixpanel.com' }
      : { debug: true, api_host: 'https://api-eu.mixpanel.com' }

  analyticsEngines.push(AnalyticsConsoleLogger)
  const [cookieConsent] = useLocalStorage(cookieConsentKey, CookieConsentValue.NotSet)

  if (mixpanelToken && cookieConsent === CookieConsentValue.Accepted) {
    analyticsEngines.push(createAnalyticsMixpanel(mixpanelToken, mixpanelConfig))
  }

  const analytics = combineAnalytics(...analyticsEngines)

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
}
