import { fetchUserAttributes, updateUserAttribute } from 'aws-amplify/auth'
import { mutate } from 'swr'
import { LoggerService } from '@tumelo/logging'
import { User } from '@/application/domain/user'

export const getUserInfoKey = 'userInfo'
const returningUserAttributeKey = 'custom:returning_user'

export const getUserInfo = async (): Promise<User> => {
  const attributes = await fetchUserAttributes()
  const { given_name: givenName, family_name: familyName, email, sub } = attributes
  const returningUser = attributes[returningUserAttributeKey]

  if (!givenName || !familyName || !email || !sub) {
    throw new Error(`user attribute missing: ${JSON.stringify({ givenName, familyName, email, sub })}`)
  }

  return {
    givenName,
    familyName,
    email,
    returningUser,
    sub,
  }
}

export const markUserAsReturning = async (logger: LoggerService): Promise<void> => {
  try {
    await updateUserAttribute({ userAttribute: { attributeKey: returningUserAttributeKey, value: 'true' } })
    mutate(getUserInfoKey)
  } catch (error) {
    logger.logError(error)
  }
}
