import { BrokerConnection } from '@/application/domain/brokerConnection'
import { BrokerConnectionSyncStatus } from '@/application/proto/tumelo/retailbff/v1/broker_connection'
import { AjBell, CharlesSchwab, Fidelity } from './broker'

export const SampleBrokerConnection = (override?: Partial<BrokerConnection>): BrokerConnection => ({
  name: `habitats/3d38abfd-82b5-4c37-8ba7-5cf20660a2de/investors/f1cbd090-366b-4c7f-b9a5-56289b1efe82/brokerConnections/7015de34-845f-4c72-a8d8-5696ac0d9f6c`,
  externalId: '123',
  broker: AjBell,
  brokerConnectionStatus: BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_CONNECTED,
  ...override,
})

export const AJBellBrokerConnection = SampleBrokerConnection()

export const CharlesSchwabBrokerConnection = SampleBrokerConnection({
  name: `habitats/3d38abfd-82b5-4c37-8ba7-5cf20660a2de/investors/f1cbd090-366b-4c7f-b9a5-56289b1efe82/brokerConnections/2b983d92-5ff1-434f-925c-272bbf3bf0d2`,
  externalId: '1234',
  broker: CharlesSchwab,
  brokerConnectionStatus: BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED,
})

export const FidelityBrokerConnection = SampleBrokerConnection({
  name: `habitats/3d38abfd-82b5-4c37-8ba7-5cf20660a2de/investors/f1cbd090-366b-4c7f-b9a5-56289b1efe82/brokerConnections/d1fc12a9-d835-4efa-8eba-b2ca5bfc64d8`,
  externalId: '12345',
  broker: Fidelity,
  brokerConnectionStatus: BrokerConnectionSyncStatus.BROKER_CONNECTION_SYNC_STATUS_DISCONNECTED_DO_NOT_RECONNECT,
})
