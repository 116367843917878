import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useHashRedirect = () => {
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) return // Wait until the router is ready

    const hashRoute = window.location.hash
    if (hashRoute.startsWith('#')) {
      const queryString = hashRoute.substring(1)
      const newPath = `${window.location.pathname}?${queryString}`
      router.push(newPath)
    }
  }, [router.isReady, router])
}
