import React, { createContext, ReactNode, useMemo } from 'react'
import { useLogger } from '@tumelo/logging'
import { useAuth, AuthResponse } from '@/application/features/auth/useAuth'
import { DefaultUser } from '@/application/Mocks/user'

const defaultAuthState: AuthResponse = {
  user: DefaultUser(),
  error: undefined,
  signOut: async () => {},
}

interface AuthProviderProps {
  children: ReactNode
}

export const UserContext = createContext<AuthResponse>(defaultAuthState)

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { user, error, signOut } = useAuth()
  const logger = useLogger()

  const value = useMemo(
    () => ({
      user,
      error,
      signOut,
    }),
    [user, error, signOut]
  )

  logger.setUserData({ id: user.sub })

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
