import { Amplify, ResourcesConfig } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import React, { useMemo } from 'react'
import '@aws-amplify/ui-react/styles.css'
import { getEnvironmentConfig } from '@/application/Environment/getEnvironmentConfig'
import { AmplifyStyles } from './AmplifyStyles'
import { AuthContainer } from './AuthContainer'

export const Auth: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { userpoolId, appClientId } = getEnvironmentConfig()

  useMemo(() => {
    const authConfig: ResourcesConfig = {
      Auth: {
        Cognito: {
          userPoolId: userpoolId,
          userPoolClientId: appClientId,
        },
      },
    }

    Amplify.configure(authConfig)
    return Auth
  }, [userpoolId, appClientId])

  return (
    <Authenticator.Provider>
      <AmplifyStyles />
      <AuthContainer>{children}</AuthContainer>
    </Authenticator.Provider>
  )
}
