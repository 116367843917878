/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.retailbff.v1";

/** Instrument is a financial instrument that has an isin */
export interface Instrument {
  /** GENERATED in format of `instruments/{isin}` */
  name: string;
  /**
   * isin is the ISIN of an instrument as defined by ISO 6166. It is used as the identifier.
   * it is part of the name and so cannot be updated. It is required on create.
   */
  isin: string;
  /**
   * title is a string name representation of the fund. It may be thought as the Issuer Name concatenated with the Name
   * of the instrument
   */
  title: string;
}

function createBaseInstrument(): Instrument {
  return { name: "", isin: "", title: "" };
}

export const Instrument = {
  encode(message: Instrument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.isin !== "") {
      writer.uint32(18).string(message.isin);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Instrument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.isin = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Instrument {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      isin: isSet(object.isin) ? String(object.isin) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: Instrument): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.isin !== "") {
      obj.isin = message.isin;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Instrument>, I>>(base?: I): Instrument {
    return Instrument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Instrument>, I>>(object: I): Instrument {
    const message = createBaseInstrument();
    message.name = object.name ?? "";
    message.isin = object.isin ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
