import { RetailBffService } from '@/application/proto/tumelo/retailbff/v1/service'
import { getEnvironmentConfig } from '@/application/Environment/getEnvironmentConfig'
import { getGrpcClient } from './Grpc'
import { MockClient } from './Mock'

export enum BFFClientType {
  Mock,
  Grpc,
}

export const BffClientTypeMap = new Map<string, BFFClientType>([
  ['mock', BFFClientType.Mock],
  ['grpc', BFFClientType.Grpc],
])

export const getBFFClient = (): Promise<RetailBffService> => {
  const { clientType, clientAddress } = getEnvironmentConfig()

  switch (clientType) {
    case BFFClientType.Mock:
      return Promise.resolve(MockClient)
    case BFFClientType.Grpc:
      return getGrpcClient(clientAddress)
    default:
      throw new Error('client type not implemented')
  }
}
