import React, { PropsWithChildren } from 'react'
import { primitives } from '@aws-amplify/ui-react'
import { AuthHeader } from './AuthHeader'

const { Flex } = primitives

export const AuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  // a reconstruction of the authenticator component to ensure we have the same layout
  return (
    <div data-amplify-authenticator="">
      <div data-amplify-container="">
        <AuthHeader />
        <div data-amplify-router="">
          <div className="amplify-tabs">
            <div className="amplify-tabs__panel amplify-tabs__panel--active">
              <div data-amplify-form="">
                <Flex direction="column">{children}</Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
