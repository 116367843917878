import React from 'react'
import { ThemeProvider } from 'styled-components'
import { SoftConfigThemeSchema } from '@tumelo/designsystem'

export const StyledComponentsThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={SoftConfigThemeSchema.parse()}>
      <>{children}</>
    </ThemeProvider>
  )
}
